










import { ref, watchEffect } from '../../../adapter-vue';
const __sfc_main = {};
__sfc_main.props = {
  open: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isSliderOpen = ref(false);
  const emits = __ctx.emit;
  watchEffect(() => {
    isSliderOpen.value = props.open;
  });
  const toggleSlider = () => {
    isSliderOpen.value = !isSliderOpen.value;
    emits('change', isSliderOpen.value);
  };
  return {
    isSliderOpen,
    toggleSlider
  };
};
export default __sfc_main;
