






import { computed } from '../../../../adapter-vue';
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const tipContent = computed(() => props.content?.text || props.content?.custom || '');
  return {
    tipContent
  };
};
export default __sfc_main;
