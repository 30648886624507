import request from '@/request';
const part_url = '/uas/im/';
export default {
    /**
     * 获取用户登录凭证
     */
    getUserSig() {
        let url = part_url + "getUserSig";
        return request.get(url);
    },
}