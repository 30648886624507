<template>
  <a-spin :spinning="isloading" tip="加载中">
    <a-tabs default-active-key="1" @change="tabChanged">
      <a-tab-pane key="1" tab="未读">
        <a-list item-layout="horizontal" :data-source="unreadlist.records">
          <a-list-item slot="renderItem" slot-scope="item">
            <template slot="actions">
              <a-button type="link" @click="readed(item.id)">已读</a-button>
            </template>
            <a-list-item-meta :description="'内容:' + item.body">
              <a slot="title">标题:{{ item.title ? item.title : "无" }}</a>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
        <a-pagination
          size="small"
          style="text-align: right; margin-top: 10px"
          :total="unreadlist.total"
          :show-total="(total) => `总数 ${unreadlist.total} 条`"
        />
      </a-tab-pane>
      <a-tab-pane key="2" tab="已读">
        <a-list item-layout="horizontal" :data-source="readlist.records">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta :description="'内容:' + item.body">
              <a slot="title">标题:{{ item.title ? item.title : "无" }}</a>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
        <a-pagination
          size="small"
          style="text-align: right; margin-top: 10px"
          :total="readlist.total"
          :show-total="(total) => `总数 ${readlist.total} 条`"
        />
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>
<script>
import messageApi from "@/api/message";
import { mapActions } from "vuex";
export default {
  name: "message",
  data() {
    return {
      isloading: false,
      activeKey: 1,
      unreadlist: {},
      readlist: {},
    };
  },
  mounted() {
    this.getMessage(1);
    this.getMessage(2);
  },
  methods: {
    ...mapActions({
      stateMessageCount: "systemMenu/setMessageCount",
    }),
    tabChanged(v) {
      this.activeKey = v;
    },
    readed(id) {
      messageApi.read(id).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getMessage(1);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    getMessage(status) {
      this.messageloading = true;
      messageApi.getForUser(1, 5, status).then((res) => {
        this.messageloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          if (status == 1) {
            this.unreadlist = res.data;
            this.stateMessageCount(Number(res.data.total));
          } else {
            this.readlist = res.data;
          }
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
  },
};
</script>