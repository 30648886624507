













import TUIChatEngine, { TUIStore, StoreName, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../../adapter-vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isNotNetwork = ref(false);
  TUIStore.watch(StoreName.USER, {
    netStateChange: (value: string) => {
      isNotNetwork.value = value === TUIChatEngine.TYPES.NET_STATE_DISCONNECTED;
    }
  });
  return {
    TUITranslateService,
    isNotNetwork
  };
};
export default __sfc_main;
