<template>
  <a-spin :spinning="loading">
    <div style="padding: 10px">
      <a-timeline mode="alternate">
        <a-timeline-item v-for="item in definition" :key="item.id" :color="item.status" style="min-height: 100px">
          <a-tag slot="dot" :color="item.status">{{ item.name }}</a-tag>
          <template v-if="item.audit && item.audit.length > 0">
            <div v-for="a in item.audit" :key="a.id" style="padding-top: 30px; text-align: left">
              <a-alert :message="a.resultStr"
                :type="a.auditResult == -1 ? 'warning' : a.auditResult == 0 ? 'error' : 'success'"
                show-icon>
                <template slot="description">
                  <div>{{ a.auditRemark }}</div>
                  <div style="text-align: right">{{ a.auditUserName }}</div>
                  <div style="text-align: right">{{ a.time }}</div>
                </template>
              </a-alert>
            </div>
          </template>
        </a-timeline-item>
      </a-timeline>
    </div>
  </a-spin>
</template>
<script>
import workflow from "@/api/workflow/index";
export default {
  name: "workflowdetail",
  data() {
    return {
      loading: false,
      definition: [],
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  mounted() {
    this.getFlow();
  },
  methods: {
    async getFlow() {
      var _this = this;
      var auditRecords = [];
      if (this.info.processModelKey.indexOf(":"))
        this.info.processModelKey = this.info.processModelKey.split(":")[0];
      await workflow
        .getAuditRecord(this.info.processModelKey, this.info.id)
        .then((res) => {
          if (res.errorCode === _this.$commons.RespCode.success) {
            auditRecords = res.data;
          }
        });
      await workflow.getFlowDetail(this.info.processId).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          var traces = [];
          if (res.data.traces) {
            res.data.traces.forEach((item) => {
              if (!_this.$util.inArray(traces, item.fromNodeId)) {
                traces.push(item.fromNodeId);
              }
              if (!_this.$util.inArray(traces, item.toNodeId)) {
                traces.push(item.toNodeId);
              }
            });
          }
          var d = JSON.parse(res.data.definition);
          var tasks = d.tasks ? d.tasks : [];
          var services = d.services ? d.services : [];
          var nodes = [d.start, d.finish, ...tasks, ...services];
          var serviceNodes = services.map((s) => s.id);
          var defs = [];
          var currentNodeId = this.info.currentNode;

          defs.push({
            id: d.start.id,
            name: d.start.name,
            status: "blue",
          });
          var from = d.start;
          while (from != null) {
            if (from.flows) {
              var node = null;
              for (var index in from.flows) {
                var flow = from.flows[index];
                //跳过 审批不通过分支
                if (flow.expression == "auditResult==0") continue;
                //判断条件支线
                if (
                  traces.length > 0 &&
                  !(
                    flow.expression == "auditResult==1" ||
                    flow.expression == null
                  )
                ) {
                  var s = traces.filter((s) => s == flow.target);
                  if (s.length == 0) continue;
                }

                var nextNodes = nodes.filter((n) => n.id === flow.target);
                if (nextNodes.length == 0) {
                  break;
                }
                node = nextNodes[0];
                if (!_this.$util.inArray(serviceNodes, node.id)) {
                  var auditItem = auditRecords.filter(
                    (a) => a.nodeId == node.id
                  );
                  var status = "gray";
                  if (auditItem.length > 0) {
                    var ai = auditItem[auditItem.length - 1];
                    status = ai.auditResult == 1 ? "green" : "red";
                    auditItem.forEach(a => {
                      a.resultStr = a.auditResult == 1 ? "通过" : "不通过";
                    })
                  }
                  if (node.id == currentNodeId) {
                    status = "orange";
                  }
                  if (
                    res.data.nextTask &&
                    node.id == res.data.nextTask.nodeId
                  ) {
                    if (res.data.nextTask.status == 2 && res.data.nextTask.waitResult == 0) {
                      status = "green";
                    }
                    var names = JSON.parse(res.data.nextTask.realName);
                    names.forEach((n) => {
                      if (
                        !auditItem.find((a) => a.auditUser == n["userName"])
                      ) {
                        auditItem.push({
                          auditUserName: n["realName"],
                          auditResult: status == "green" ? 1 : -1,
                          resultStr: status != "green" ? "待处理" : (res.data.nextTask.waitResult == 0 ? "已办理" : "通过"),
                        });
                      }
                    });
                  }
                  if (node.name == "结束" && this.info.status != 1) {
                    status = "blue";
                  }
                  defs.push({
                    id: node.id,
                    name: node.name,
                    status: status,
                    audit: auditItem,
                  });
                }

                break;
              }

              if (node == null) {
                break;
              }
              from = node;
            } else {
              break;
            }
          }
          this.definition = defs;
        }
      });
    },
  },
};
</script>