



















































import { ref, watchEffect } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { isPC, isH5, isUniFrameWork } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  show: {
    type: Boolean,
    default: false
  },
  isHeaderShow: {
    type: Boolean,
    default: true
  },
  isFooterShow: {
    type: Boolean,
    default: true
  },
  background: {
    type: Boolean,
    default: true
  },
  title: {
    type: String,
    default: ''
  },
  center: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const clickType = {
    OUTSIDE: 'outside',
    INSIDE: 'inside'
  };
  const props = __props;
  const showDialog = ref(false);
  const isHeaderShowDialog = ref(true);
  const isFooterShowDialog = ref(true);
  const backgroundDialog = ref(true);
  const showTitle = ref('');
  watchEffect(() => {
    showDialog.value = props.show;
    showTitle.value = props.title;
    isHeaderShowDialog.value = props.isHeaderShow;
    isFooterShowDialog.value = props.isFooterShow;
    backgroundDialog.value = props.background;
  });
  const emit = __ctx.emit;
  const toggleView = (type: string) => {
    if (type === clickType.OUTSIDE) {
      close();
    }
  };
  const close = () => {
    showDialog.value = !showDialog.value;
    emit('update:show', showDialog.value);
  };
  const submit = () => {
    emit('submit');
    close();
  };
  return {
    TUITranslateService,
    isPC,
    isH5,
    isUniFrameWork,
    clickType,
    showDialog,
    isHeaderShowDialog,
    isFooterShowDialog,
    backgroundDialog,
    showTitle,
    toggleView,
    close,
    submit
  };
};
export default __sfc_main;
