
























































import { computed, ref, withDefaults, watch } from '../../../../adapter-vue';
import TUIChatEngine, { TUITranslateService, IGroupModel, Friend, FriendApplication } from '@tencentcloud/chat-uikit-engine';
import { IUserStatusMap, IContactInfoType } from '../../../../interface';
import Avatar from '../../../common/Avatar/index.vue';
import { generateAvatar, generateName, acceptFriendApplication } from '../../utils';
// type IItemType = IGroupModel | Friend | FriendApplication | IBlackListUserItem;
const __sfc_main = {};
__sfc_main.props = {
  item: {
    key: "item",
    required: false,
    type: null,
    default: () => ({} as IContactInfoType)
  },
  list: {
    key: "list",
    required: false,
    type: Array,
    default: () => ([] as Array<IContactInfoType>)
  },
  displayOnlineStatus: {
    key: "displayOnlineStatus",
    required: false,
    type: Boolean,
    default: () => false
  },
  userOnlineStatusMap: {
    key: "userOnlineStatusMap",
    required: false,
    type: null,
    default: () => ({} as IUserStatusMap)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isOnline = ref<boolean>(false);
  const groupType = {
    [TUIChatEngine.TYPES.GRP_WORK]: 'Work',
    [TUIChatEngine.TYPES.GRP_AVCHATROOM]: 'AVChatRoom',
    [TUIChatEngine.TYPES.GRP_PUBLIC]: 'Public',
    [TUIChatEngine.TYPES.GRP_MEETING]: 'Meeting',
    [TUIChatEngine.TYPES.GRP_COMMUNITY]: 'Community'
  };
  const otherContentForSow = computed((): string => {
    let content = '';
    if ((props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_TO_ME || (props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_BY_ME) {
      content = (props.item as FriendApplication)?.wording || '';
    } else if ((props.item as IGroupModel)?.groupID) {
      content = `ID:${(props.item as IGroupModel)?.groupID}`;
    }
    return content;
  });
  const groupTypeForShow = computed((): string => {
    let type = '';
    if ((props.item as IGroupModel)?.groupID) {
      type = groupType[(props.item as IGroupModel)?.type];
    }
    return type;
  });
  const showApplicationStatus = computed(() => {
    if ((props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_BY_ME) {
      // 我发出的好友申请
      return {
        style: 'text',
        label: '等待验证'
      };
    } else if ((props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_TO_ME) {
      // 我收到的好友申请
      return {
        style: 'button',
        label: '同意',
        onClick: () => {
          acceptFriendApplication((props.item as FriendApplication)?.userID);
        }
      };
    }
    return false;
  });
  watch(() => props.userOnlineStatusMap, () => {
    isOnline.value = getOnlineStatus();
  }, {
    immediate: true,
    deep: true
  });
  function getOnlineStatus(): boolean {
    return !!(props.displayOnlineStatus && props.userOnlineStatusMap && (props.item as Friend)?.userID && props.userOnlineStatusMap?.[(props.item as Friend).userID]?.statusType === TUIChatEngine.TYPES.USER_STATUS_ONLINE);
  }
  return {
    TUITranslateService,
    generateAvatar,
    generateName,
    props,
    isOnline,
    otherContentForSow,
    groupTypeForShow,
    showApplicationStatus
  };
};
__sfc_main.components = Object.assign({
  Avatar
}, __sfc_main.components);
export default __sfc_main;
