import request from '@/request';

const part_url = "/user/";
export default {

    /**
     * 修改密码
     * @param {*} params userId 用户id；password 旧密码；newPassword 新密码
     */
    updatePassword(params) {
        let url =  "/uas/login/updatePassword?appKey=uas";
        return request.post(url, params, {});
    },
    
    getRolesAll() {
        let url = "/uas/getAllRoles";
        return request.get(url);
    },
   
}