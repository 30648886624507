import request from "@/request"
const part_url = "/workflow/";
export default{
    getList(processModelKey){
        let url = part_url + "definition/getList";
        return request.get(url,{processModelKey}, {});
    },
    getProcessModelMeta(){
        let url = "/oa/workflow/getProcessModelMeta";
        return request.get(url,{}, {});
    },
    saveOrUpdate(params){
        let url = part_url + "definition/saveOrUpdate";
        return request.post(url,{}, params);
    },
    postAuditData(params){
        let url = "/oa/workflow/audit";
        return request.post(url,{}, params);
    },
    buildSub(data){
        let url = part_url+"buildSub";
        return request.post(url,{}, data);
    },
    getAuditRecord(processModelKey,businessId){
        let url = "/oa/workflow/getAuditRecord";
        return request.post(url,{processModelKey,businessId}, {});
    },
    getFlowDetail(instanceId){
        let url = part_url+"getFlowDetail";
        return request.get(url,{instanceId}, {});
    },
    getDefinitionForTask(taskId){
        let url = part_url+"definition/getDefinitionForTask";
        return request.get(url,{taskId}, {});
    },
    getDefinitionForModel(modelKey){
        let url = part_url+"definition/getDefinitionForModel";
        return request.get(url,{modelKey}, {});
    },
    getTaskForUser(pageNow,pageSize){
        let url = part_url+"getTasks";
        return request.get(url,{pageNow,pageSize}, {});
    },
    getProcessForUser(pageNow,pageSize){
        let url = part_url+"getProcessModels";
        return request.get(url,{pageNow,pageSize}, {});
    },
    getTask(instanceId){
        let url = part_url+"getTask";
        return request.get(url,{instanceId}, {});
    },
    publish(id){
        let url = part_url + "definition/publish";
        return request.get(url,{id}, {});
    },
    remove(id){
        let url = part_url + "definition/remove";
        return request.get(url,{id}, {});
    },
    finishTask(id){
        let url = part_url + "finishTask";
        return request.get(url,{id}, {});
    },
    writeAuditRecord(id,dId){
        return request.get("/share/v3/custom/writeAuditRecord",{id,dId}, {});
    }
}
