const User = {
    userId: "userId",// 用户id
    userName: "userName", // 登录用户名
    Authentication: "Authenticationdls", // 个人凭证
    roles: "roles", // 角色
    authoritys: "authoritys", // 个人权限
    menus: "menus", // 菜单
    tenant: "tenant", // 租户id
    agencyCode: "agencyCode", // 机构编号
    trainType: "trainType" // 机构可培训类型
}

const RespCode = {
    success: "0000", // 通过
    fail: "0001", // 失败
    noAuthority: "0002", // 没权限
    exception: "0003", // 异常
    needLogin: "0004", // 凭证失效
}


export default {
    User,
    RespCode,
}