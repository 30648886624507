
























import { ref, computed, onMounted, onUnmounted } from '../../../../adapter-vue';
import TUIChatEngine, { TUIStore, StoreName, TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import closeIcon from '../../../../assets/icon/icon-close.svg';
import { isPC, isUniFrameWork } from '../../../../utils/env';
import { decodeTextMessage } from '../../utils/emojiList';
const __sfc_main = {};
__sfc_main.props = ['currentFunction'];
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const TYPES = TUIChatEngine.TYPES;
  const quoteMessage = ref<IMessageModel>();
  onMounted(() => {
    TUIStore.watch(StoreName.CHAT, {
      quoteMessage: onQuoteMessageUpdated
    });
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: onConversationIDUpdated
    });
  });
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.CHAT, {
      quoteMessage: onQuoteMessageUpdated
    });
    TUIStore.unwatch(StoreName.CONV, {
      currentConversationID: onConversationIDUpdated
    });
  });
  const quoteContentText = computed(() => {
    let _quoteContentText;
    switch (quoteMessage.value?.type) {
      case TYPES.MSG_TEXT:
        _quoteContentText = decodeTextMessage(quoteMessage.value.payload?.text);
        break;
      case TYPES.MSG_IMAGE:
        _quoteContentText = TUITranslateService.t('TUIChat.图片');
        break;
      case TYPES.MSG_AUDIO:
        _quoteContentText = TUITranslateService.t('TUIChat.语音');
        break;
      case TYPES.MSG_VIDEO:
        _quoteContentText = TUITranslateService.t('TUIChat.视频');
        break;
      case TYPES.MSG_FILE:
        _quoteContentText = TUITranslateService.t('TUIChat.文件');
        break;
      case TYPES.MSG_CUSTOM:
        _quoteContentText = TUITranslateService.t('TUIChat.自定义');
        break;
      case TYPES.MSG_FACE:
        _quoteContentText = TUITranslateService.t('TUIChat.表情');
        break;
      default:
        _quoteContentText = TUITranslateService.t('TUIChat.消息');
        break;
    }
    return _quoteContentText;
  });
  function cancelQuote() {
    TUIStore.update(StoreName.CHAT, 'quoteMessage', {
      message: undefined,
      type: 'quote'
    });
  }
  function onQuoteMessageUpdated(options?: {
    message: IMessageModel;
    type: string;
  }) {
    if (options?.message && options?.type === 'quote') {
      quoteMessage.value = options.message;
    } else {
      quoteMessage.value = undefined;
    }
  }
  function onConversationIDUpdated() {
    cancelQuote();
  }
  return {
    closeIcon,
    isPC,
    isUniFrameWork,
    props,
    quoteMessage,
    quoteContentText,
    cancelQuote
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
