






































import { ref, watch, onMounted } from '../../../adapter-vue';
import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import TUICore, { ExtensionInfo, TUIConstants } from '@tencentcloud/tui-core';
import { outsideClick } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import searchMoreSVG from '../../../assets/icon/search-more.svg';
import { isPC, isUniFrameWork } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  searchType: {
    type: String,
    default: 'global',
    // "global":全局搜索, "conversation":会话内搜索
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const searchMoreRef = ref<HTMLElement | null>();
  const isListShow = ref<boolean>(false);
  const toggleMore = () => {
    isListShow.value = !isListShow.value;
    if (!isUniFrameWork && isListShow.value) {
      outsideClick.listen({
        domRefs: searchMoreRef.value,
        handler: closeSearchMore
      });
    }
  };
  const extensionList = ref<Array<ExtensionInfo>>([]);
  const handleMenu = (item: ExtensionInfo) => {
    const {
      listener = {
        onClicked: () => {}
      }
    } = item;
    listener?.onClicked?.(item);
    toggleMore();
  };
  const closeSearchMore = () => {
    isListShow.value = false;
  };
  onMounted(() => {
    // extensions
    extensionList.value = [...TUICore.getExtensionList(TUIConstants.TUISearch.EXTENSION.SEARCH_MORE.EXT_ID)];
    // hide conversation header
    TUICore.callService({
      serviceName: TUIConstants.TUIConversation.SERVICE.NAME,
      method: TUIConstants.TUIConversation.SERVICE.METHOD.HIDE_CONVERSATION_HEADER,
      params: {}
    });
  });
  watch(() => isListShow.value, () => {
    if (isListShow.value) {
      TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
        isSearching: false,
        searchType: props.searchType
      });
    }
  });
  return {
    searchMoreSVG,
    isPC,
    searchMoreRef,
    isListShow,
    toggleMore,
    extensionList,
    handleMenu
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
