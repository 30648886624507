import request from '@/request';

const part_url = "/uas/org/";
export default {
    getTree(excludePosition,path,onlyLead) {
        let url = part_url + (path?"getTreeWithUserPath":"getTree");
        return request.get(url,{excludePosition,onlyLead});
    },
    getUsersForOrg(pageNow,pageSize,search){
        let url = part_url + "getUsersForOrg";
        search.pageNow=pageNow;
        search.pageSize=pageSize;
        return request.get(url,search);
    },
    getMatchUsers(data) {
        let url = "/uas/getMatchUsers";
        return request.post(url,{},data);
    },
    getSignPictures(data) {
        let url = "/uas/getSignPictures";
        return request.post(url,{},data);
    },
}