



































































import { ref, nextTick, onMounted, computed, getCurrentInstance } from '../../../adapter-vue';
import TUIChatEngine, { IConversationModel, TUIStore, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { CONV_OPERATION } from '../../../constant';
import { isPC, isUniFrameWork } from '../../../utils/env';
import Overlay from '../../common/Overlay/index.vue';
import Dialog from '../../common/Dialog/index.vue';
interface IProps {
  actionsMenuPosition: {
    top: number;
    left?: number;
    conversationHeight?: number;
  };
  selectedConversation: IConversationModel | undefined;
  selectedConversationDomRect: DOMRect | undefined;
}
const __sfc_main = {};
__sfc_main.props = {
  actionsMenuPosition: {
    key: "actionsMenuPosition",
    required: true,
    type: Object
  },
  selectedConversation: {
    key: "selectedConversation",
    required: true,
    type: null
  },
  selectedConversationDomRect: {
    key: "selectedConversationDomRect",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const thisInstance = getCurrentInstance()?.proxy || getCurrentInstance();
  const actionsMenuDomRef = ref<HTMLElement | null>();
  const isHiddenActionsMenu = ref(true);
  const isShowDeleteConversationDialog = ref<boolean>(false);
  const currentConversation = TUIStore.getConversationModel(props.selectedConversation?.conversationID || '');
  const _actionsMenuPosition = ref<{
    top: number;
    left?: number;
    conversationHeight?: number;
  }>(props.actionsMenuPosition);
  onMounted(() => {
    checkExceedBounds();
  });
  const deleteConversationDialogTitle = computed(() => {
    return props.selectedConversation?.type === TUIChatEngine.TYPES.CONV_C2C ? 'TUIConversation.删除后，将清空该聊天的消息记录' : props.selectedConversation?.type === TUIChatEngine.TYPES.CONV_GROUP ? 'TUIConversation.删除后，将清空该群聊的消息记录' : '';
  });
  function checkExceedBounds() {
    // 组件初始渲染时，执行并自检边界有没有超出屏幕，在nextTick中处理。
    nextTick(() => {
      if (isUniFrameWork) {
        // check exceed bounds
        const query = TUIGlobal?.createSelectorQuery().in(thisInstance);
        query.select(`#conversation-actions-menu`).boundingClientRect(data => {
          if (data) {
            // check if actionsMenu is exceed bottom of the screen
            if (data.bottom > TUIGlobal?.getWindowInfo?.().windowHeight) {
              _actionsMenuPosition.value = {
                ...props.actionsMenuPosition,
                top: props.actionsMenuPosition.top - (props.actionsMenuPosition.conversationHeight || 0) - data.height
              };
            }
            // check if actionsMenu is exceed right of the screen
            if (_actionsMenuPosition.value.left + data.width + 5 > TUIGlobal.getWindowInfo().windowWidth) {
              _actionsMenuPosition.value.left = TUIGlobal.getWindowInfo().windowWidth - data.width - 5;
            }
          }
          isHiddenActionsMenu.value = false;
        }).exec();
      } else {
        // 处理原生Vue菜单低于屏幕的情况
        const rect = actionsMenuDomRef.value?.getBoundingClientRect();
        // PC端根据鼠标点击的位置设置actionsMenu的位置，否则使用默认值167px
        if (isPC && typeof props.actionsMenuPosition.left !== 'undefined') {
          _actionsMenuPosition.value.left = props.actionsMenuPosition.left;
        }
        if (rect && rect.bottom > window.innerHeight) {
          _actionsMenuPosition.value.top = props.actionsMenuPosition.top - (props.actionsMenuPosition.conversationHeight || 0) - rect.height;
        }
        isHiddenActionsMenu.value = false;
      }
    });
  }
  const handleItem = (params: {
    name: string;
  }) => {
    const {
      name
    } = params;
    const conversationModel = currentConversation;
    if (!name || !conversationModel || !conversationModel.conversationID) {
      return;
    }
    switch (name) {
      case CONV_OPERATION.DELETE:
        conversationModel?.deleteConversation();
        break;
      case CONV_OPERATION.ISPINNED:
        conversationModel?.pinConversation();
        break;
      case CONV_OPERATION.DISPINNED:
        conversationModel?.pinConversation();
        break;
      case CONV_OPERATION.MUTE:
        conversationModel?.muteConversation();
        break;
      case CONV_OPERATION.NOTMUTE:
        conversationModel?.muteConversation();
        break;
    }
    emits('closeConversationActionMenu');
  };
  const deleteConversation = () => {
    isShowDeleteConversationDialog.value = true;
  };
  const updateShowDeleteConversationDialog = (isShow: boolean) => {
    if (!isShow) {
      emits('closeConversationActionMenu');
    }
    isShowDeleteConversationDialog.value = isShow;
  };
  return {
    TUITranslateService,
    CONV_OPERATION,
    isPC,
    emits,
    props,
    actionsMenuDomRef,
    isHiddenActionsMenu,
    isShowDeleteConversationDialog,
    _actionsMenuPosition,
    deleteConversationDialogTitle,
    handleItem,
    deleteConversation,
    updateShowDeleteConversationDialog
  };
};
__sfc_main.components = Object.assign({
  Overlay,
  Dialog
}, __sfc_main.components);
export default __sfc_main;
