











































import { ref, toRefs } from '../../../adapter-vue';
import { isUniFrameWork } from '../../../utils/env';
interface IProps {
  url: string;
  size?: string;
  borderRadius?: string;
  useSkeletonAnimation?: boolean;
}
interface IEmits {
  (key: 'onLoad', e: Event): void;
  (key: 'onError', e: Event): void;
}
const __sfc_main = {};
__sfc_main.props = {
  url: {
    key: "url",
    required: false,
    type: String,
    default: 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
  },
  size: {
    key: "size",
    required: false,
    type: String,
    default: '36px'
  },
  borderRadius: {
    key: "borderRadius",
    required: false,
    type: String,
    default: '5px'
  },
  useSkeletonAnimation: {
    key: "useSkeletonAnimation",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const defaultAvatarUrl = 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png';
  const emits = __ctx.emit;
  const props = __props;
  const {
    size: avatarSize,
    url: avatarImageUrl,
    borderRadius: avatarBorderRadius,
    useSkeletonAnimation: useAvatarSkeletonAnimation
  } = toRefs(props);
  const isImgLoaded = ref<boolean>(false);
  const loadErrorInUniapp = ref<boolean>(false);
  function avatarLoadSuccess(e: Event) {
    isImgLoaded.value = true;
    emits('onLoad', e);
  }
  function avatarLoadFailed(e: Event) {
    if (isUniFrameWork) {
      loadErrorInUniapp.value = true;
    } else {
      (e.currentTarget as HTMLImageElement).src = defaultAvatarUrl;
    }
    emits('onError', e);
  }
  return {
    isUniFrameWork,
    defaultAvatarUrl,
    avatarSize,
    avatarImageUrl,
    avatarBorderRadius,
    useAvatarSkeletonAnimation,
    isImgLoaded,
    loadErrorInUniapp,
    avatarLoadSuccess,
    avatarLoadFailed
  };
};
export default __sfc_main;
