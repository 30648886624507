<template xmlns="http://www.w3.org/1999/html">
  <div style="text-align: left">
    {{ taskShowName }}
    <ul>
      <li v-for="item in audits" :key="item.id">
        <div>{{ taskName }}{{ item.auditRemark }}</div>
        <div v-if="!showRole" style="text-align: right">
          <!--                <span v-if="showRole">{{ roleName }}</span>-->
          <img
            style="width: 120px"
            :src="item.auditUserSignPicture"
            v-if="item.auditUserSignPicture"
          />
          <span v-else>{{ item.auditUserName }} </span>
        </div>
        <div v-if="!showRole" style="text-align: right">
          <!--                <span v-if="showRole">日期：</span>-->
          {{ item.time }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import commons from "@/utils/commons";

export default {
  name: "auditPrint",
  props: {
    infoData: {
      type: Object,
    },
    taskPrintKey: {
      type: String,
    },
    showRole: {
      type: Boolean,
    },
    taskName: {
      type: String,
    },
  },
  data() {
    return {
      isCurrentUser: true,
      audits: [],
      auditModal: {
        info: null,
        visible: false,
      },
      roleName: "",
      taskShowName: "",
    };
  },
  watch: {
    infoData() {
      this.setValue();
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      if (this.infoData.auditRecords) {
        let _userName = localStorage.getItem(commons.User.userName);
        var f = this.infoData.auditRecords.filter(
          (a) => a.taskPrintKey == this.taskPrintKey
        );
        if (f.length > 0) {
          f.forEach((i) => {
            i.time = this.$moment(i.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
          });
          var _f = f.filter((a) => a.auditUser == _userName);
          if (_f.length > 0) {
            this.isCurrentUser = false;
          }
          this.audits = f;
        } else this.taskShowName = this.taskName;
      } else this.taskShowName = this.taskName;
    },
    showaudit() {
      this.auditModal.info = this.infoData;
      this.auditModal.visible = true;
    },
    auditCallBack(d) {
      if (d != null) {
        d.processExecuteStatus = 0;
        this.infoData.auditRecords = [d];
        this.setValue();
      }
      this.auditModal.visible = false;
      this.$emit("callback", d != null);
    },
  },
};
</script>