























































































import { ref, watch, watchEffect } from '../../../adapter-vue';
import TUIChatEngine, { TUITranslateService, TUIUserService, TUIConversationService, TUIFriendService, TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { IUserProfile } from '../../../interface';
import { isUniFrameWork } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  userInfo: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isFriendShip = ref(false);
  const userInfoManager = ref<IUserProfile>({});
  watchEffect(() => {
    userInfoManager.value = props.userInfo;
  });
  const emits = __ctx.emit;
  watch(() => props.userInfo, async (newVal: any, oldVal: any) => {
    if (newVal === oldVal) return;
    const res = await TUIUserService.getUserProfile({
      userIDList: [props.userInfo.userID]
    });
    userInfoManager.value = res?.data[0];
    // 这里需要确认是否是好友关系
    checkFriend();
  }, {
    deep: true,
    immediate: true
  });
  const enter = async (ID: any, type: string) => {
    const name = `${type}${ID}`;
    TUIConversationService.getConversationProfile(name).then((res: any) => {
      TUIConversationService.switchConversation(res.data.conversation.conversationID).then(() => {
        TUIStore.update(StoreName.GRP, 'isShowManageComponent', false);
        if (isUniFrameWork) {
          TUIGlobal?.navigateBack();
        }
      });
    }).catch((err: any) => {
      console.warn('获取会话资料失败', err.code, err.msg);
    });
  };
  const checkFriend = async () => {
    if (!(userInfoManager.value as any).userID) return;
    TUIFriendService.checkFriend({
      userIDList: [userInfoManager.value.userID],
      type: TUIChatEngine.TYPES.SNS_CHECK_TYPE_BOTH
    }).then((res: any) => {
      const relation = res?.data?.successUserIDList?.[0]?.relation;
      isFriendShip.value = relation === TUIChatEngine.TYPES.SNS_TYPE_BOTH_WAY;
    });
  };
  const showEnter = () => {
    return isFriendShip.value || !TUIStore.getData(StoreName.APP, 'isOfficial');
  };
  const close = (tabName: string) => {
    emits('close', tabName);
  };
  return {
    TUITranslateService,
    isUniFrameWork,
    userInfoManager,
    enter,
    showEnter,
    close
  };
};
export default __sfc_main;
