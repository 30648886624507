const Login = {
  "登录": "log in",
  "登录当前账号": "Log in with current account",
  "切换其他账号": "Switch accounts",
  "我已阅读并同意": "I have read and agree to",
  "和": "and",
  "隐私条例": "Privacy Policy",
  "用户协议": "User Agreement",
  "免费体验": "Free trial",
  "体验更多Demo": "More demos",
  "访问官网": "Official website",
  "超10亿用户的信赖": "1+ billion users trust Tencent Cloud Instant Messenger",
  "一分钟": "1 Minute",
  "改2行代码，1分钟跑通demo": "Quick demo run in 1 minute with changing only 2 lines of code required",
  "每月服务客户数超过10000家": "Over 10,000 customers per month",
  "消息收发成功率": "Success rate of message sending & receiving and service reliability higher than 99.99%",
  "10亿": "1 billion",
  "每月活跃用户数超过10亿": "Over 1 billion active users per month",
  "请输入手机号": "Enter a mobile number",
  "请输入userID": "Please enter your userID",
  "请输入验证码": "Enter the verification code",
  "请输入正确的手机号": "Enter a valid mobile number",
  "请先勾选用户协议": "Agree to the User Agreement first",
  "获取验证码": "Send verification code",
  "登录失败": "Login failed",
  "验证码发送成功": "Verification code sent successfully",
  "发送验证码失败": "Failed to send the verification code",
  "Android": "Android",
  "iOS": "iOS",
  "小程序": "Applet",
  "Windows": "Windows",
  "Mac OS": "Mac OS",
  "扫描二维码下载": "Applet scanning QR code download",
  "微信扫码进入": " WeChat scanning QR code to enter",
  "点击直接下载": "Click to download directly",
  "更多客户端体验": "More client experiences",
  "微信扫一扫，免费体验腾讯云 IM 小程序": "Scan wechat and experience Tencent cloud im applet for free",
  "或者截图至相册，切换微信扫一扫识别体验": "Or take a screenshot to the photo album to switch the wechat scanning recognition experience"
}
export default Login;
