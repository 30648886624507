








































































































import TUIChatEngine, { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { watchEffect, ref } from '../../../adapter-vue';
import Icon from '../../common/Icon.vue';
import delIcon from '../../../assets/icon/del-icon.svg';
import { IGroupSelfInfo, IGroupMember } from '../../../interface';
import { isUniFrameWork } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  list: {
    type: Array,
    default: () => []
  },
  total: {
    type: Number,
    default: () => 0
  },
  isShowDel: {
    type: Boolean,
    default: () => false
  },
  self: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const totalMember = ref(0);
  const memberList = ref<Array<IGroupMember>>([]);
  const isShowDeleteBtn = ref(false);
  const selfValue = ref<IGroupSelfInfo>({});
  watchEffect(() => {
    totalMember.value = props.total;
    isShowDeleteBtn.value = props.isShowDel;
    memberList.value = (props.list as Array<IGroupMember>);
    selfValue.value = props.self;
  });
  const emits = __ctx.emit;
  const handleRoleName = (item: any) => {
    let name = '';
    switch (item?.role) {
      case TUIChatEngine.TYPES.GRP_MBR_ROLE_ADMIN:
        name = TUITranslateService.t('TUIGroup.管理员');
        break;
      case TUIChatEngine.TYPES.GRP_MBR_ROLE_OWNER:
        name = TUITranslateService.t('TUIGroup.群主');
        break;
    }
    if (name) {
      name = `(${name})`;
    }
    if (item.userID === selfValue.value.userID) {
      name += ` (${TUITranslateService.t('TUIGroup.我')})`;
    }
    return name;
  };
  const getMore = () => {
    emits('more');
  };
  const submit = (item: any) => {
    emits('del', [item]);
  };
  const handleMemberProfileShow = (user: any) => {
    emits('handleMemberProfileShow', user);
  };
  const close = (tabName: string) => {
    emits('close', tabName);
  };
  return {
    TUITranslateService,
    delIcon,
    isUniFrameWork,
    totalMember,
    memberList,
    isShowDeleteBtn,
    handleRoleName,
    getMore,
    submit,
    handleMemberProfileShow,
    close
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
