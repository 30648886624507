






























































































import { TUITranslateService, TUIStore, StoreName, IConversationModel, TUIChatService, SendMessageParams } from '@tencentcloud/chat-uikit-engine';
import { ref, computed } from '../../../../adapter-vue';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import evaluateIcon from '../../../../assets/icon/evaluate.svg';
import Link from '../../../../utils/documentLink';
import Icon from '../../../common/Icon.vue';
import starIcon from '../../../../assets/icon/star.png';
import starLightIcon from '../../../../assets/icon/star-light.png';
import { CHAT_MSG_CUSTOM_TYPE } from '../../../../constant';
import { isPC, isH5, isUniFrameWork } from '../../../../utils/env';
import { isEnabledMessageReadReceiptGlobal } from '../../utils/utils';
const __sfc_main = {};
__sfc_main.props = {
  starTotal: {
    type: Number,
    default: 5
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const container = ref();
  const starList = ref<number>(props.starTotal);
  const currentStarIndex = ref<number>(-1);
  const comment = ref('');
  const currentConversation = ref<IConversationModel>();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  const isEvaluateValid = computed(() => comment.value.length || currentStarIndex.value >= 0);
  const onDialogShow = () => {
    emits('onDialogPopupShowOrHide', true);
  };
  const onDialogClose = () => {
    resetEvaluate();
    emits('onDialogPopupShowOrHide', false);
  };
  const openLink = () => {
    if (isPC || isH5) {
      window.open(Link?.customMessage?.url);
    }
  };
  const closeDialog = () => {
    container?.value?.toggleDialogDisplay(false);
  };
  const resetEvaluate = () => {
    currentStarIndex.value = -1;
    comment.value = '';
  };
  const selectStar = (starIndex?: any) => {
    if (currentStarIndex.value === starIndex) {
      currentStarIndex.value = currentStarIndex.value - 1;
    } else {
      currentStarIndex.value = starIndex;
    }
  };
  const submitEvaluate = () => {
    // 评价消息，星星数和文本必须有一个才可以提交
    if (currentStarIndex.value < 0 && !comment.value.length) {
      return;
    }
    const options = {
      to: currentConversation?.value?.groupProfile?.groupID || currentConversation?.value?.userProfile?.userID,
      conversationType: currentConversation?.value?.type,
      payload: {
        data: JSON.stringify({
          businessID: CHAT_MSG_CUSTOM_TYPE.EVALUATE,
          version: 1,
          score: currentStarIndex.value + 1,
          comment: comment.value
        }),
        description: '对本次的服务评价',
        extension: '对本次的服务评价'
      },
      needReadReceipt: isEnabledMessageReadReceiptGlobal()
    };
    TUIChatService.sendCustomMessage((options as SendMessageParams));
    // 提交后关闭 dialog
    // close dialog after submit evaluate
    container?.value?.toggleDialogDisplay(false);
  };
  return {
    TUITranslateService,
    evaluateIcon,
    Link,
    starIcon,
    starLightIcon,
    isPC,
    isUniFrameWork,
    container,
    starList,
    currentStarIndex,
    comment,
    isEvaluateValid,
    onDialogShow,
    onDialogClose,
    openLink,
    closeDialog,
    selectStar,
    submitEvaluate
  };
};
__sfc_main.components = Object.assign({
  ToolbarItemContainer,
  Icon
}, __sfc_main.components);
export default __sfc_main;
