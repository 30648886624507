






























































import { ref, onMounted, framework } from './adapter-vue';
import { TUILogin } from '@tencentcloud/tui-core';
import { TUIStore, StoreName, TUIConversationService } from '@tencentcloud/chat-uikit-engine';
import { TUISearch, TUIConversation, TUIChat, TUIContact, TUIGroup } from './components';
import { isH5 } from './utils/env';
const __sfc_main = {};
__sfc_main.props = {
  // 部署生产环境时不需要通过 props 传入 SDKAppID、userID、userSig
  // eslint-disable-next-line vue/prop-name-casing
  SDKAppID: {
    type: Number,
    default: 0,
    required: false
  },
  userID: {
    type: String,
    default: '',
    required: false
  },
  userSig: {
    type: String,
    default: '',
    required: false
  },
  conversationID: {
    type: String,
    default: '',
    required: false // 独立集成 Chat 时需要传 conversationID
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const currentConversationID = ref<string>('');
  const currentNavbar = ref<string>('conversation');
  const navbarList = [{
    id: 'conversation',
    label: '会话'
  }, {
    id: 'contact',
    label: '通讯录'
  }];
  const props = __props;
  onMounted(() => {
    // 监听当前会话 ID
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: (id: string) => {
        currentConversationID.value = id;
      }
    });

    // 默认登录逻辑
    login();

    // H5 环境下修改 CallKit 样式
    modifyCallKitStyle();
  });
  function login() {
    const {
      SDKAppID,
      userID,
      userSig,
      conversationID
    } = props;
    if (SDKAppID && userID && userSig) {
      TUILogin.login({
        SDKAppID,
        userID,
        userSig,
        useUploadPlugin: true,
        framework
      }).then(() => {
        // 独立集成 Chat 时执行以下代码
        if (conversationID.startsWith('C2C') || conversationID.startsWith('GROUP')) {
          TUIConversationService.switchConversation(conversationID);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      }).catch(error => {});
    }
  }
  function modifyCallKitStyle() {
    if (isH5) {
      const bodyStyle = document.getElementsByTagName('body')[0].style;
      bodyStyle.setProperty('--callLeft', '0');
      bodyStyle.setProperty('--callTop', '0');
      bodyStyle.setProperty('--callWidth', '100%');
      bodyStyle.setProperty('--callHeight', '100%');
    }
  }
  return {
    isH5,
    currentConversationID,
    currentNavbar,
    navbarList
  };
};
__sfc_main.components = Object.assign({
  TUISearch,
  TUIConversation,
  TUIChat,
  TUIGroup,
  TUIContact
}, __sfc_main.components);
export default __sfc_main;
