
















































































import { watchEffect, ref } from '../../../../adapter-vue';
import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { isUrl, JSONToObject } from '../../../../utils/index';
import { CHAT_MSG_CUSTOM_TYPE } from '../../../../constant';
import { ICustomMessagePayload } from '../../../../interface';
import Icon from '../../../common/Icon.vue';
import star from '../../../../assets/icon/star-light.png';
interface Props {
  messageItem: IMessageModel;
  content: any;
}
const __sfc_main = {};
__sfc_main.props = {
  messageItem: {
    key: "messageItem",
    required: false,
    type: null,
    default: undefined
  },
  content: {
    key: "content",
    required: false,
    type: null,
    default: undefined
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const custom = ref();
  const message = ref<IMessageModel>();
  const extension = ref();
  const isCustom = ref<ICustomMessagePayload>({
    businessID: ''
  });
  watchEffect(() => {
    custom.value = props.content;
    message.value = props.messageItem;
    const {
      payload
    } = props.messageItem;
    isCustom.value = payload.data || '';
    isCustom.value = JSONToObject(payload.data);
    if (payload.data === CHAT_MSG_CUSTOM_TYPE.SERVICE) {
      extension.value = JSONToObject(payload.extension);
    }
  });
  const openLink = (url: any) => {
    window.open(url);
  };
  return {
    TUITranslateService,
    isUrl,
    CHAT_MSG_CUSTOM_TYPE,
    star,
    extension,
    isCustom,
    openLink
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
