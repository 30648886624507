import { routes } from '@/router'

const hasPermission = (authoritys, route) => {
    if (route.meta) {
        if(!route.meta.menu){
            return false;
        }
        if(route.meta.requireAuth){
            return authoritys.some(role =>route.name===role)
        }
        return true;
        
    } else {
        return true
    }
}

const filterAsyncRoutes = (routes, authoritys) => {
    const res = []
    routes.forEach(route => {
        const tmp = { ...route }
        delete tmp.component;
        if (hasPermission(authoritys, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, authoritys)
                if(tmp.children && tmp.children.length>0){
                    res.push(tmp)
                }
            }else{
                res.push(tmp)
            }
            
        }
    })
    return res
}

const state = {
    accessedRoutes: []
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.accessedRoutes = routes;
    }
}

const actions = {
    generateRoutes({ commit }, authoritys) {
        return new Promise(resolve => {
            let accessedRoutes = filterAsyncRoutes(routes, authoritys)
            commit('SET_ROUTES', accessedRoutes)
            resolve(accessedRoutes)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}