

































































































import { nextTick } from '../../../adapter-vue';
import { TUITranslateService, IGroupModel } from '@tencentcloud/chat-uikit-engine';
import { watchEffect, ref } from '../../../adapter-vue';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import { isUniFrameWork } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  data: {
    type: Object,
    default: () => ({})
  },
  isAuthor: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const groupProfile = ref<IGroupModel>({});
  const input = ref('');
  const isAuthorNotification = ref(false);
  const isEdit = ref(false);
  watchEffect(() => {
    groupProfile.value = props.data;
    input.value = groupProfile.value.notification;
    isAuthorNotification.value = props.isAuthor;
  });
  const emits = __ctx.emit;

  // 更新群资料
  const updateProfile = () => {
    if (input.value.length > 150) {
      Toast({
        message: TUITranslateService.t('TUIGroup.群公告字数超出限制，最大长度为150'),
        type: TOAST_TYPE.ERROR
      });
      return;
    }
    if (input.value && input.value !== groupProfile.value.notification) {
      emits('update', {
        key: 'notification',
        value: input.value
      });
      nextTick(() => {
        input.value = '';
      });
    }
    isEdit.value = !isEdit.value;
  };
  const close = (tabName: string) => {
    emits('close', tabName);
  };
  return {
    TUITranslateService,
    isUniFrameWork,
    groupProfile,
    input,
    isAuthorNotification,
    isEdit,
    updateProfile,
    close
  };
};
export default __sfc_main;
