import request from "@/request"
const part_url = "/share/message/";

export default{
    getForUser(pageNow,pageSize,status){
        let url = part_url+"getForUser";
        return request.get(url,{pageNow,pageSize,status}, {});
    },
    getUnreadCount(){
        let url = part_url+"getUnreadCount";
        return request.get(url,{}, {});
    },
    read(id){
        let url = part_url+"read";
        return request.get(url,{id}, {});
    }
}