<template xmlns="http://www.w3.org/1999/html">
  <div style="text-align: left">
    <ul>
      <li v-for="item in audits" :key="item.id">
        <div>{{ item.auditRemark }}</div>
        <div v-if="!showRole" style="text-align: right">
          <img
            style="width: 120px"
            :src="item.auditUserSignPicture"
            v-if="item.auditUserSignPicture"
          />
          <span v-else>{{ item.auditUserName }} </span>
        </div>
        <div v-if="!showRole" style="text-align: right">
          {{ item.time }}
        </div>
      </li>
    </ul>
    <a-button
      type="link"
      v-if="
        infoData.processExecuteStatus &&
        infoData.taskKey == taskKey &&
        isCurrentUser
      "
      @click="showaudit"
      >审批</a-button
    >
    <a-modal
      title="审批"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </div>
</template>
<script>
import commons from "@/utils/commons";

export default {
  name: "auditshow",
  props: {
    infoData: {
      type: Object,
    },
    taskKey: {
      type: String,
    },
    showRole: {
      type: Boolean,
    },
  },
  data() {
    return {
      isCurrentUser: true,
      audits: [],
      auditModal: {
        info: null,
        visible: false,
      },
      roleName: "",
    };
  },
  watch: {
    infoData() {
      this.setValue();
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      if (this.infoData.auditRecords) {
        let _userName = localStorage.getItem(commons.User.userName);
        var f = this.infoData.auditRecords.filter(
          (a) => a.taskKey == this.taskKey
        );
        if (f.length > 0) {
          f.forEach((i) => {
            i.time = this.$moment(i.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
          });
          var _f = f.filter((a) => a.auditUser == _userName);
          if (_f.length > 0) {
            this.isCurrentUser = false;
          }
          this.audits = f;
        }
      }
    },
    showaudit() {
      this.auditModal.info = this.infoData;
      this.auditModal.visible = true;
    },
    auditCallBack(d) {
      if (d != null) {
        d.processExecuteStatus = 0;
        this.infoData.auditRecords = [d];
        this.setValue();
      }
      this.auditModal.visible = false;
      this.$emit("callback", d != null);
    },
  },
};
</script>