














import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { isPC } from '../../../../utils/env';
import Icon from '../../../common/Icon.vue';
import SearchDefaultIcon from '../../../../assets/icon/search-default.svg';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {
    TUITranslateService,
    isPC,
    SearchDefaultIcon
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
