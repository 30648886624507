





































import { TUITranslateService, TUIStore, StoreName, IConversationModel, SendMessageParams, TUIChatService } from "@tencentcloud/chat-uikit-engine";
import { ref } from "../../../../adapter-vue";
import ToolbarItemContainer from "../toolbar-item-container/index.vue";
import wordsIcon from "../../../../assets/icon/words.svg";
import { wordsList } from "../../utils/wordsList";
import { isEnabledMessageReadReceiptGlobal } from "../../utils/utils";
import { isPC, isUniFrameWork } from "../../../../utils/env";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const currentConversation = ref<IConversationModel>();
  const container = ref();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  const selectWord = (item: any) => {
    const options = ({
      to: currentConversation?.value?.groupProfile?.groupID || currentConversation?.value?.userProfile?.userID,
      conversationType: currentConversation?.value?.type,
      payload: {
        text: TUITranslateService.t(`Words.${item.value}`)
      },
      needReadReceipt: isEnabledMessageReadReceiptGlobal()
    } as SendMessageParams);
    TUIChatService.sendTextMessage(options);
    // 提交后关闭 dialog
    // close dialog after submit evaluate
    container?.value?.toggleDialogDisplay(false);
  };
  const closeDialog = () => {
    container?.value?.toggleDialogDisplay(false);
  };
  const onDialogShow = () => {
    emits("onDialogPopupShowOrHide", true);
  };
  const onDialogClose = () => {
    emits("onDialogPopupShowOrHide", false);
  };
  return {
    TUITranslateService,
    wordsIcon,
    wordsList,
    isPC,
    isUniFrameWork,
    container,
    selectWord,
    closeDialog,
    onDialogShow,
    onDialogClose
  };
};
__sfc_main.components = Object.assign({
  ToolbarItemContainer
}, __sfc_main.components);
export default __sfc_main;
