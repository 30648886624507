





































































import { ref, computed } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
// dayjs extension
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import 'dayjs/locale/zh-cn';
import DatePickerPanel from './date-picker-panel.vue';
import { DateCell } from './date-picker';
import { isPC, isUniFrameWork } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  type: {
    type: String,
    default: 'range' // "single":单选, 暂不支持 / "range":区间选择
  },
  rangeTableType: {
    type: String,
    default: 'one' // "one": 在 一个 datePanel 之内选择区间（注意，移动端因屏幕较窄，仅支持在一个 datePanel 中进行选择） / "two": 在 两个 datePanel 之间选择区间
  },
  startPlaceholder: {
    type: String,
    default: () => TUITranslateService.t('开始时间')
  },
  endPlaceholder: {
    type: String,
    default: () => TUITranslateService.t('开始时间')
  },
  popupPosition: {
    type: String,
    default: 'bottom' // "top": 向上弹出 datePanel / "bottom": 向下弹出 datePanel
  },
  // 默认单选日期
  defaultSingleDate: {
    type: Dayjs,
    default: null,
    required: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  dayjs.extend(localeData);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.locale('zh-cn');
  const emit = __ctx.emit;
  const props = __props;
  const isDatePanelShow = ref<boolean>(false);
  const dateValue = ref<typeof Dayjs>(props.type === 'single' ? props?.defaultSingleDate : null);
  const startValue = ref<typeof Dayjs>(props.type === 'single' ? props?.defaultSingleDate : null);
  const endValue = ref<typeof Dayjs>(props.type === 'single' ? props?.defaultSingleDate : null);
  const startFormatDate = computed(() => startValue?.value?.format('YYYY/MM/DD'));
  const endFormatDate = computed(() => endValue?.value?.format('YYYY/MM/DD'));
  const startPlaceholderVal = props.startPlaceholder;
  const endPlaceholderVal = props.endPlaceholder;
  const leftCurrentPanelValue = ref<typeof Dayjs>();
  const rightCurrentPanelValue = ref<typeof Dayjs>();
  const setDatePanelDisplay = (show: boolean) => {
    isDatePanelShow.value = show;
  };
  const n = (classNameList: Array<string>) => {
    const resultClassList: Array<string> = [];
    classNameList.forEach((className: string) => {
      if (className) {
        resultClassList.push('tui-date-picker-' + className);
        !isPC && resultClassList.push('tui-date-picker-h5-' + className);
      } else {
        resultClassList.push('tui-date-picker');
        !isPC && resultClassList.push('tui-date-picker-h5');
      }
    });
    return resultClassList;
  };
  const handlePick = (cell: DateCell) => {
    switch (props.type) {
      case 'single':
        startValue.value = cell.date;
        endValue.value = cell.date;
        dateValue.value = cell.date;
        emit('change', cell);
        emit('pick', dateValue.value);
        // 避免生硬直接关闭
        setTimeout(() => {
          setDatePanelDisplay(false);
        }, 300);
        break;
      case 'range':
        if (!startValue?.value) {
          startValue.value = cell.date;
        } else if (!endValue?.value) {
          if (startValue?.value?.isSameOrBefore(cell.date, 'day')) {
            endValue.value = cell.date;
          } else {
            endValue.value = startValue.value;
            startValue.value = cell.date;
          }
          emit('pick', {
            startDate: startValue?.value?.startOf('date'),
            endDate: endValue?.value?.endOf('date')
          });
          // 避免生硬直接关闭
          setTimeout(() => {
            setDatePanelDisplay(false);
          }, 200);
        } else {
          startValue.value = cell.date;
          endValue.value = null;
        }
        emit('change', {
          startDate: startValue.value,
          endDate: endValue.value,
          leftCurrentPanel: leftCurrentPanelValue.value,
          rightCurrentPanel: leftCurrentPanelValue.value
        });
        break;
    }
  };
  const handleLeftPanelChange = (value: typeof Dayjs) => {
    leftCurrentPanelValue.value = value;
    emit('change', {
      startDate: startValue.value,
      endDate: endValue.value,
      leftCurrentPanel: leftCurrentPanelValue.value,
      rightCurrentPanel: leftCurrentPanelValue.value
    });
  };
  const handleRightPanelChange = (value: typeof Dayjs) => {
    rightCurrentPanelValue.value = value;
    emit('change', {
      startDate: startValue.value,
      endDate: endValue.value,
      leftCurrentPanel: leftCurrentPanelValue.value,
      rightCurrentPanel: leftCurrentPanelValue.value
    });
  };
  return {
    isPC,
    isUniFrameWork,
    props,
    isDatePanelShow,
    dateValue,
    startValue,
    endValue,
    startFormatDate,
    endFormatDate,
    startPlaceholderVal,
    endPlaceholderVal,
    leftCurrentPanelValue,
    rightCurrentPanelValue,
    setDatePanelDisplay,
    n,
    handlePick,
    handleLeftPanelChange,
    handleRightPanelChange
  };
};
__sfc_main.components = Object.assign({
  DatePickerPanel
}, __sfc_main.components);
export default __sfc_main;
