

































import { ref, watch } from '../../../adapter-vue';
import Overlay from '../../common/Overlay/index.vue';
interface IProps {
  visible: boolean;
  popDirection: 'top' | 'right' | 'bottom' | 'left';
  isFullScreen?: boolean | undefined;
  overlayColor?: string | undefined;
  drawerStyle?: {
    bottom?: Record<string, any> | undefined;
    right?: Record<string, any> | undefined;
    left?: Record<string, any> | undefined;
    top?: Record<string, any> | undefined;
  };
}
interface IEmits {
  (e: 'onOverlayClick', event: Event): void;
}
const __sfc_main = {};
__sfc_main.props = {
  visible: {
    key: "visible",
    required: false,
    type: Boolean,
    default: true
  },
  popDirection: {
    key: "popDirection",
    required: false,
    type: String,
    default: 'bottom'
  },
  isFullScreen: {
    key: "isFullScreen",
    required: false,
    type: [Boolean, null],
    default: true
  },
  overlayColor: {
    key: "overlayColor",
    required: false,
    type: [String, null]
  },
  drawerStyle: {
    key: "drawerStyle",
    required: false,
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const drawerDomRef = ref<HTMLElement>();
  const overlayDomInstanceRef = ref<InstanceType<typeof Overlay>>();
  const isDrawerShow = ref<boolean>(false);
  const styles = ref(props.drawerStyle[props.popDirection] || {});
  watch(() => props.visible, (visible: boolean) => {
    if (visible) {
      isDrawerShow.value = true;
    } else {
      setTimeout(() => {
        isDrawerShow.value = false;
      }, 150);
    }
  }, {
    immediate: true
  });
  function onOverlayClick(e: Event) {
    emits('onOverlayClick', e);
  }
  return Object.assign({
    props,
    drawerDomRef,
    overlayDomInstanceRef,
    isDrawerShow,
    styles,
    onOverlayClick
  }, {
    drawerDomRef,
    overlayDomRef: overlayDomInstanceRef.value?.overlayDomRef
  });
};
__sfc_main.components = Object.assign({
  Overlay
}, __sfc_main.components);
export default __sfc_main;
