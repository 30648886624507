



import { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import TUICustomerServicePlugin from '@tencentcloud/tui-customer-service-plugin/index.vue';
interface IProps {
  message: IMessageModel;
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {
    props
  };
};
__sfc_main.components = Object.assign({
  TUICustomerServicePlugin
}, __sfc_main.components);
export default __sfc_main;
