// 生成UUID
const uuid = () => {
    let s = [];
    let hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";
    return s.join("");
}
/**
 * 将boolean类型转化为数字型0 1 
 *
 * @param {*} s
 * @returns
 */
function changeBooleanInt(s) {
    return s ? 1 : 0;
}

// 检查手机号是否合法
const checkPhone = (phone) => {
    let partten = /^(13[0-9]|14[5-9]|15[012356789]|16[5-7]|17[0-8]|18[0-9]|19[135689])[0-9]{8}$/;
    if (partten.test(phone)) {
        return true;
    } else {
        return false;
    }
}

// 严格检查身份证号是否合法
const checkIdCard = (idCard) => {
    //检查身份证号码
    let reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
    let city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 "
    };
    let pass = true;
    if (!idCard || !reg.test(idCard)) {
        pass = false;
    } else if (!city[idCard.substr(0, 2)]) {
        pass = false;
    } else {
        //18位身份证需要验证最后一位校验位
        if (idCard.length == 18) {
            idCard = idCard.split('');
            //加权因子
            let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            //校验位
            let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
            let parity1 = [1, 0, 'x', 9, 8, 7, 6, 5, 4, 3, 2];
            let sum = 0;
            let ai = 0;
            let wi = 0;
            for (let i = 0; i < 17; i++) {
                ai = idCard[i];
                wi = factor[i];
                sum += ai * wi;
            }
            if (parity[sum % 11] != idCard[17] && parity1[sum % 11] != idCard[17]) {
                pass = false;
            }
        }
    }
    return pass;
}

// 简单检查身份证号是否合法
const checkIdCardSimple = (idCard) => {
    if (idCard.length === 0) {
        return false;
    }
    let idCardRegex_18 = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    let idCardRegex_15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;
    return idCardRegex_18.test(idCard) || idCardRegex_15.test(idCard);
}

const int2Boolean = (s) => {
    return s == 1 ? true : false;
}

const boolean2Int = (b) => {
    return b ? 1 : 0;
}


// 通过数组中对象的某个字段对数组进行分组
const groupArray = (array, groupKey) => {
    let result = {};
    for (let i = 0; i < array.length; i++) {
        let obj = array[i];
        if (obj[groupKey]) {
            result[obj[groupKey]] = [];
        }
    }
    for (let key in result) {
        for (let j = 0; j < array.length; j++) {
            let obj2 = array[j];
            if (obj2[groupKey] && key == obj2[groupKey]) {
                result[key].push(obj2);
            }
        }
    }
    return result;
}

// 属性数据构建成数据结构
const buildTree = (list, parentId, idKey = "id", parentIdKey = "parentId") => {
    let result = [];
    if (!list) {
        return result;
    }
    for (let i = 0; i < list.length; i++) {
        if (list[i][parentIdKey] === parentId) {
            let children = buildTree(list, list[i][idKey], idKey, parentIdKey);
            if (children) {
                list[i].children = children;
            }
            result.push(list[i]);
        }
    }
    return result;
}

// 去除html文本中的标签
const replaceHtmlTag = (str) => {
    if (!str) {
        return '';
    }
    let htmlRegex = /<\/?.+?>/g;
    str = str.replace(htmlRegex, '');
    let blankRegex = /^\s*|\s*/;
    str = str.replace(blankRegex, '');
    return str.replace('&nbsp;', ' ');
};

const isWps = (format) => {
    const formats = [
        'xls', 'xlsx', 'doc', 'docx', 'dotx', 'ppt', 'pptx', 'pdf'
    ];
    let result = formats.some((item) => {
        return item === format
    });
    return result;
}

const inArray = (arr, item) => {
    if (arr) {
        for (let i in arr) {
            if (arr[i] === item) {
                return true;
            }
        }
    }
    return false;
}
//大写：
const menoyToUppercase = (money) => {
    let cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
    let cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
    let cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
    let cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
    let cnInteger = '整';	//整数金额时后面跟的字符
    let cnIntLast = '元';	//整数完以后的单位
    //最大处理的数字
    let maxNum = 999999999999999.9999;
    let integerNum;	 //金额整数部分
    let decimalNum;	 //金额小数部分
    //输出的中文金额字符串
    let chineseStr = '';
    let parts;		//分离金额后用的数组，预定义
    if (money == '') { return ''; }

    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return '超出最大处理数字';
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //四舍五入保留两位小数,转换为字符串
    money = Math.round(money * 100).toString();
    integerNum = money.substr(0, money.length - 2);
    decimalNum = money.substr(money.length - 2);
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        let IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
            let n = integerNum.substr(i, 1);
            let p = IntLen - i - 1;
            let q = p / 4;
            let m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != '') {
        let decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
        chineseStr += cnInteger;
    }
    return chineseStr;
}

export default {
    inArray,
    uuid,
    checkPhone,
    checkIdCard,
    checkIdCardSimple,
    int2Boolean,
    boolean2Int,
    groupArray,
    buildTree,
    replaceHtmlTag,
    isWps,
    changeBooleanInt,
    menoyToUppercase
}