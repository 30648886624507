








import Loading from '../../../common/Loading/index.vue';
import { isPC } from '../../../../utils/env';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {
    isPC
  };
};
__sfc_main.components = Object.assign({
  Loading
}, __sfc_main.components);
export default __sfc_main;
