



























































import { computed, ref, onMounted } from '../../../adapter-vue';
import Icon from '../../common/Icon.vue';
import Server, { IMenuItem } from './server';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const showChildren = ref<Array<IMenuItem>>([]);
  const convHeaderRef = ref<HTMLElement | undefined>();
  const menuList = computed(() => {
    return Server.getInstance().getMenu();
  });
  onMounted(() => {
    showChildren.value = [];
  });
  const handleMenu = (item: IMenuItem) => {
    const {
      data: {
        children
      },
      listener = {
        onClicked: () => {}
      }
    } = item;
    if (children) {
      showChildren.value = showChildren.value.length > 0 ? [] : children;
    } else {
      listener.onClicked(item);
      closeChildren();
    }
  };
  const closeChildren = () => {
    showChildren.value = [];
  };
  return Object.assign({
    showChildren,
    convHeaderRef,
    menuList,
    handleMenu
  }, {
    closeChildren
  });
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
