






































































import { watchEffect, ref, nextTick, watch } from '../../../adapter-vue';
import { TUITranslateService, IGroupModel } from '@tencentcloud/chat-uikit-engine';
import Icon from '../../common/Icon.vue';
import editIcon from '../../../assets/icon/edit.svg';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import { isPC } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  data: {
    type: Object,
    default: () => ({})
  },
  isAuthor: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const groupProfile = ref<IGroupModel>({});
  const inputGroupName = ref('');
  const isEdit = ref(false);
  const nameInputRef = ref<HTMLInputElement>(null);
  watchEffect(() => {
    groupProfile.value = props.data;
  });
  const emit = __ctx.emit;
  const updateProfile = () => {
    if (!inputGroupName.value) {
      Toast({
        message: TUITranslateService.t('TUIGroup.群名称不能为空'),
        type: TOAST_TYPE.ERROR
      });
    } else {
      if (inputGroupName.value !== groupProfile.value.name) {
        emit('update', {
          key: 'name',
          value: inputGroupName.value
        });
        groupProfile.value.name = inputGroupName.value;
        inputGroupName.value = '';
        Toast({
          message: TUITranslateService.t('TUIGroup.群名称修改成功'),
          type: TOAST_TYPE.SUCCESS
        });
      }
      close();
    }
  };
  const close = () => {
    if (props.isAuthor) {
      isEdit.value = !isEdit.value;
    }
    if (isEdit.value) {
      inputGroupName.value = groupProfile.value.name;
    }
  };
  watch(() => isEdit.value, (newVal: boolean) => {
    if (newVal) {
      nextTick(() => {
        nameInputRef.value?.focus();
      });
    }
  });
  return {
    TUITranslateService,
    editIcon,
    isPC,
    groupProfile,
    inputGroupName,
    isEdit,
    nameInputRef,
    updateProfile,
    close
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
