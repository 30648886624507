
import commons from "@/utils/commons.js"
import login from "@/api/system/login"
import resetRouter from "@/router"
const currentUserX = {
    namespaced: true,

    state: {
        token: "",
        userId: "",
        userName: "",
        rules: "",
        authoritys: "",
        menus:""
    },
    mutations: {
        /**
         * 设置当前登录者信息
         * @param {*} state 
         * @param {*} payload  字符串
         */
        SET_CURRENT_USER(state, payload) {
            state.userName = payload;
            if (payload == "") {
                localStorage.removeItem(commons.User.userName);
            } else {
                localStorage.setItem(commons.User.userName, payload);
            }
        },
        /**
         * 设置用户id
         * @param {*} state 
         * @param {*} payload 
         */
        SET_USER_ID(state, payload) {
            state.userId = payload;
            if (payload == "") {
                localStorage.removeItem(commons.User.userId);
            } else {
                localStorage.setItem(commons.User.userId, payload);
            }
        },
        /**
         * 设置用户token
         * @param {*} state 
         * @param {*} payload 字符串
         */
        SET_TOKEN(state, payload) {
            state.token = payload;
            if (payload == "") {
                localStorage.removeItem(commons.User.Authentication);
            } else {
                localStorage.setItem(commons.User.Authentication, payload);
            }
        },
        SET_MENUS(state, payload) {
            state.menus = payload;
            if (payload == "") {
                localStorage.removeItem(commons.User.menus);
            } else {
                localStorage.setItem(commons.User.menus, payload);
            }
        },
        /**
         * 设置用户角色
         * @param {*} state 
         * @param {*} payload 
         */
        SET_ROLES(state, payload) {
            state.rules = payload;
            if (payload == "") {
                localStorage.removeItem(commons.User.roles);
            } else {
                localStorage.setItem(commons.User.roles, payload);
            }
        },
        /**
         * 设置当前用户的权限
         * @param {*} state 
         * @param {*} payload 
         */
        SET_AUTHORITYS(state, payload) {
            state.authoritys = payload;
            if (payload == "") {
                localStorage.removeItem(commons.User.authoritys)
            } else {
                localStorage.setItem(commons.User.authoritys, payload)
            }
        }
        
    },
    getters: {
        /**
         * 获取当前登录者用户名
         */
        getUserName: (state) => {
            return state.userName || localStorage.getItem(commons.User.userName);
        },
        /**
         * 获取用户id
         * @param {*} state 
         */
        getUserId: (state) => {
            return state.userId || localStorage.getItem(commons.User.userId);
        },
        /**
         * 获取用户token
         */
        getToken: (state) => {
            return state.token || localStorage.getItem(commons.User.Authentication);
        },
        getMenus: (state) => {
            return state.menus || localStorage.getItem(commons.User.menus);
        },
        /**
         * 获取用户角色
         */
        getRoles: (state) => {
            return state.rules || localStorage.getItem(commons.User.rules);
        },
        /**
         * 获取用户权限
         */
        getAuthoritys: (state) => {
            return state.authoritys || localStorage.getItem(commons.User.authoritys);
        }
    
       
    },
    actions: {
        /**
         * 退出系统
         * @param {*} param
         * @param {*} payload 
         */
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                login.logout().then(response => {
                    const code = response.errorCode;
                    const message = response.errorMsg;
                    if (response.errorCode === commons.RespCode.success) {
                        commit("SET_CURRENT_USER", "");
                        commit("SET_USER_ID", "");
                        commit("SET_TOKEN", "");
                        commit("SET_ROLES", "");
                        commit("SET_AUTHORITYS", "");
                        commit("SET_MENUS", "");
                        resetRouter();
                    }
                    resolve({ "code": code, "message": message });
                }).catch(() => {
                    reject();
                });
            }).catch(e => { console.error(e) });
        },
        /**
         * 登录系统
         * @param {*} param0 
         * @param {*} payload 
         */
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let loginData = {
                    userName: payload.name,
                    password: payload.password,
                    code: payload.code,
                    uuid: payload.uuid,
                }
                login.login(loginData, payload.token).then(response => {
                    const code = response.errorCode;
                    const message = response.errorMsg;
                    if (response.errorCode === commons.RespCode.success) {
                        const token = response.data.token;
                        let auths = response.data.authorizes
                        if(auths==null){
                            auths=[];
                        }
                        let authoritys = [];
                       
                        for (let size = auths.length, i = 0; i < size; i++) {
                            if(auths[i].type=='MenuPurviewClaim'){
                                commit("SET_MENUS", auths[i].value);
                            }else if (auths[i].type!='DataPurviewClaim') {
                                let vals =auths[i].value;
                                if(Array.isArray(vals)){
                                    for (let len = vals.length, j = 0; j < len; j++) {
                                        authoritys.push(`${auths[i].type}-${vals[j]}`);
                                    }
                                }else{
                                    authoritys.push(`${auths[i].type}-${auths[i].value}`);
                                }
                            }
                        }
                        commit("SET_CURRENT_USER", payload.name);
                        commit("SET_TOKEN", token);
                        commit("SET_AUTHORITYS", authoritys.join());
                    }
                    resolve({ "code": code, "message": message });
                }).catch(() => {
                    reject();
                });
            }).catch(e => { console.error(e) });
        },
        /**
         * 清除授权
         * @param {*} param0 
         */
        clearAuth({ commit }) {
            commit("SET_CURRENT_USER", "");
            commit("SET_USER_ID", "");
            commit("SET_TOKEN", "");
            commit("SET_ROLES", "");
            commit("SET_AUTHORITYS", "");
            commit("SET_MENUS", "");
        }
    }
}

export default currentUserX