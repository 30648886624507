<template>
  <a-breadcrumb style="margin: 8px 0; text-align: left">
    <a-breadcrumb-item :key="index" v-for="(item, index) in breadcrumbs">
      <template> {{ item }}</template>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>
<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbs: [],
    };
  },

  mounted() {
    this.$nextTick(function () {
      this.changeRoute(this.$route);
    });
  },
  watch: {
    $route: function (to, from) {
      this.changeRoute(to);
    },
  },
  methods: {
    changeRoute(route) {
      this.breadcrumbs = [];
      if (route.path == "/home" || route.path == "/work/home") {
        this.breadcrumbs = [];
        return;
      }
      for (var i = 0; i < route.matched.length; ++i) {
        this.breadcrumbs.push(route.matched[i].meta.name);
      }
    },
  },
};
</script>

<style>
</style>

