























import TUIChatEngine, { TUIStore, StoreName, TUITranslateService, IConversationModel } from '@tencentcloud/chat-uikit-engine';
import TUICore, { TUIConstants, ExtensionInfo } from '@tencentcloud/tui-core';
import { ref, onMounted, onUnmounted } from '../../../adapter-vue';
import Icon from '../../common/Icon.vue';
import backSVG from '../../../assets/icon/back.svg';
import { isPC } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const currentConversation = ref<IConversationModel>();
  const currentConversationName = ref('');
  const typingStatus = ref(false);
  const groupID = ref('');
  const extensions = ref<ExtensionInfo[]>([]);
  onMounted(() => {
    TUIStore.watch(StoreName.CONV, {
      currentConversation: onCurrentConversationUpdated
    });
    TUIStore.watch(StoreName.CHAT, {
      typingStatus: onTypingStatusUpdated
    });
  });
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.CONV, {
      currentConversation: onCurrentConversationUpdated
    });
    TUIStore.unwatch(StoreName.CHAT, {
      typingStatus: onTypingStatusUpdated
    });
  });
  const closeChat = (conversationID: string | undefined) => {
    emits('closeChat', conversationID);
  };
  const handleExtensions = (item: ExtensionInfo) => {
    item.listener.onClicked?.({
      groupID: groupID.value
    });
  };
  function onCurrentConversationUpdated(conversation: IConversationModel) {
    const isGroup = conversation?.type === TUIChatEngine.TYPES.CONV_GROUP;
    if (isGroup && currentConversation.value?.conversationID !== conversation?.conversationID) {
      extensions.value = TUICore.getExtensionList(TUIConstants.TUIChat.EXTENSION.CHAT_HEADER.EXT_ID, {
        filterManageGroup: !isGroup
      });
    }
    if (!isGroup) {
      extensions.value = [];
    }
    currentConversation.value = conversation;
    groupID.value = currentConversation.value?.groupProfile?.groupID;
    currentConversationName.value = currentConversation?.value?.getShowName();
  }
  function onTypingStatusUpdated(status: boolean) {
    typingStatus.value = status;
    if (typingStatus.value) {
      currentConversationName.value = TUITranslateService.t('TUIChat.对方正在输入');
    } else {
      currentConversationName.value = currentConversation.value?.getShowName() || '';
    }
  }
  return {
    backSVG,
    isPC,
    currentConversation,
    currentConversationName,
    extensions,
    closeChat,
    handleExtensions
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
