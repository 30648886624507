

















































import { ref, watch, onMounted, onUnmounted, nextTick } from '../../../../../adapter-vue';
import { TUIStore, StoreName, IMessageModel, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { getBoundingClientRectSync } from '@tencentcloud/universal-api';
import Icon from '../../../../common/Icon.vue';
import checkIcon from '../../../../../assets/icon/check-sm.svg';
import { ITranslateInfo } from '../../../../../interface';
import { Translator } from '../../../utils/translation';
interface IProps {
  message: IMessageModel;
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const translationVisible = ref<boolean>(false);
  const translationFinished = ref<boolean>(false);
  const translationError = ref<boolean>(false);
  const translationText = ref<string>('');
  const calculateHeight = ref<number>(0);
  const calculateWidth = ref<number>(0);
  const translationLoadingRef = ref<HTMLDivElement>();
  const translationWrapperRef = ref<HTMLDivElement>();
  const translationContentRef = ref<HTMLDivElement>();
  let isSingleTranslation = true;
  onMounted(() => {
    TUIStore.watch(StoreName.CHAT, {
      translateTextInfo: onMessageTranslationUpdated
    });
  });
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.CHAT, {
      translateTextInfo: onMessageTranslationUpdated
    });
  });
  function onMessageTranslationUpdated(info: Map<string, ITranslateInfo[]>) {
    if (info === undefined) return;
    isSingleTranslation = false;
    const translationInfoList = info.get(props.message.conversationID) || [];
    for (let i = 0; i < translationInfoList.length; ++i) {
      const {
        messageID,
        visible
      } = translationInfoList[i];
      if (messageID === props.message.ID && visible !== undefined) {
        if (translationInfoList.length === 1 && visible) {
          isSingleTranslation = true;
        }
        translationVisible.value = visible;
        break;
      }
    }
  }
  watch(translationVisible, (newVal: boolean) => {
    if (newVal) {
      Translator.get(props.message).then(text => {
        translationFinished.value = true;
        translationText.value = text;
        nextTick(() => {
          const {
            height: originHeight,
            width: originWidth
          } = getBoundingClientRectSync(translationLoadingRef.value!);
          const {
            height,
            width
          } = getBoundingClientRectSync(translationContentRef.value!);
          calculateHeight.value = originHeight;
          calculateWidth.value = originWidth;
          requestAnimationFrame(() => {
            calculateHeight.value = height;
            calculateWidth.value = width;
            if (isSingleTranslation) {
              nextTick(() => {
                const {
                  bottom
                } = getBoundingClientRectSync(translationWrapperRef.value!);
                const {
                  bottom: bottomWindow
                } = getBoundingClientRectSync('#messageScrollList');
                if (bottom > bottomWindow) {
                  setTimeout(() => {
                    translationWrapperRef.value!.scrollIntoView({
                      block: 'end',
                      behavior: 'smooth'
                    });
                  }, 150);
                }
              });
            }
          });
        });
      }).catch(err => {
        translationFinished.value = true;
        translationError.value = true;
        const {
          height: originHeight
        } = getBoundingClientRectSync(translationLoadingRef.value!);
        calculateHeight.value = originHeight;
        translationText.value = err.message;
      });
    } else {
      translationFinished.value = false;
      translationError.value = false;
      translationText.value = '';
      calculateHeight.value = 0;
      calculateWidth.value = 0;
    }
  });
  return {
    TUITranslateService,
    checkIcon,
    props,
    translationVisible,
    translationFinished,
    translationError,
    translationText,
    calculateHeight,
    calculateWidth,
    translationLoadingRef,
    translationWrapperRef,
    translationContentRef
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
