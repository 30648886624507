










import { ref, watchEffect } from '../../../adapter-vue';
import { isWeChat } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  show: {
    type: Boolean,
    default: () => false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const showMask = ref(false);
  watchEffect(() => {
    showMask.value = props.show;
  });
  const emit = __ctx.emit;
  const toggleView = () => {
    showMask.value = !showMask.value;
    emit('update:show', showMask.value);
  };
  return {
    isWeChat,
    showMask,
    toggleView
  };
};
export default __sfc_main;
