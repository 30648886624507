
























































import { ref, onMounted, onUnmounted } from '../../../adapter-vue';
import { TUIStore, StoreName, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import searchIcon from '../../../assets/icon/search.svg';
import closeIcon from '../../../assets/icon/input-close.svg';
import { isPC } from '../../../utils/env';
import { ISearchInputValue, ISearchingStatus } from '../type';
const __sfc_main = {};
__sfc_main.props = {
  placeholder: {
    type: String,
    default: () => TUITranslateService.t('TUISearch.搜索')
  },
  searchType: {
    type: String,
    default: 'global',
    // "global":全局搜索, "conversation":会话内搜索
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const searchValueModel = ref<string>('');
  const currentSearchInputValue = ref<string>('');
  const searchingStatus = ref<boolean>(false);
  function onCurrentSearchInputValueChange(obj: ISearchInputValue) {
    if (obj?.searchType === props?.searchType) {
      currentSearchInputValue.value = obj?.value;
      searchValueModel.value = obj?.value;
    }
  }
  function onCurrentSearchingStatusChange(obj: ISearchingStatus) {
    if (obj?.searchType === props?.searchType) {
      searchingStatus.value = obj?.isSearching;
    }
  }
  onMounted(() => {
    TUIStore.watch(StoreName.SEARCH, {
      currentSearchInputValue: onCurrentSearchInputValueChange,
      currentSearchingStatus: onCurrentSearchingStatusChange
    });
  });
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.SEARCH, {
      currentSearchInputValue: onCurrentSearchInputValueChange,
      currentSearchingStatus: onCurrentSearchingStatusChange
    });
  });
  const search = () => {
    // 避免重复搜索
    if (searchValueModel.value === currentSearchInputValue.value) {
      return;
    }
    TUIStore.update(StoreName.SEARCH, 'currentSearchInputValue', {
      value: searchValueModel.value,
      searchType: props.searchType
    });
  };
  const endSearching = () => {
    searchingStatus.value = false;
    TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
      isSearching: false,
      searchType: props.searchType
    });
    TUIStore.update(StoreName.SEARCH, 'currentSearchInputValue', {
      value: '',
      searchType: props.searchType
    });
  };
  const onSearchInputClick = () => {
    TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
      isSearching: true,
      searchType: props.searchType
    });
  };
  const onBlur = () => {
    TUIGlobal?.hideKeyboard?.();
  };
  return {
    TUITranslateService,
    searchIcon,
    closeIcon,
    isPC,
    props,
    searchValueModel,
    searchingStatus,
    search,
    endSearching,
    onSearchInputClick,
    onBlur
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
