


















import { computed, CSSProperties, onMounted, ref, watch } from '../../../adapter-vue';
import { isH5 } from '../../../utils/env';
import TOAST_TYPE from './type';
const __sfc_main = {};
__sfc_main.props = {
  message: {
    type: String,
    default: ''
  },
  duration: {
    type: Number,
    default: 3000
  },
  repeatNum: {
    type: Number,
    default: 1
  },
  id: {
    type: String,
    default: ''
  },
  onClose: {
    type: Function,
    required: false
  },
  offset: {
    type: Number,
    default: 20
  },
  zIndex: {
    type: Number,
    default: 0
  },
  type: {
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const visible = ref(false);
  let timer: any;
  const startTimer = () => {
    if (props.duration > 0) {
      timer = setTimeout(() => {
        if (visible.value) {
          close();
        }
      }, props.duration);
    }
  };
  const clearTimer = () => {
    clearTimeout(timer);
  };
  const close = () => {
    visible.value = false;
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };
  watch(() => props.repeatNum, () => {
    clearTimer();
    startTimer();
  });
  const customStyle = computed<CSSProperties>(() => ({
    top: `${props.offset}px`,
    zIndex: props.zIndex
  }));
  onMounted(() => {
    startTimer();
    visible.value = true;
  });
  const handleStyle = (type?: string) => {
    if (type && (type === TOAST_TYPE.ERROR || type === TOAST_TYPE.SUCCESS || type === TOAST_TYPE.WARNING)) return type;
    return TOAST_TYPE.NORMAL;
  };
  return {
    isH5,
    visible,
    customStyle,
    handleStyle
  };
};
export default __sfc_main;
