<template>
  <img style="width:120px" :src="auditUserSignPicture" v-if="auditUserSignPicture"/>
  <span v-else>{{ auditUserName }} </span>
<!--  <span>{{ auditUserName }}</span>-->
</template>
<script>
export default {
    name:"auditUser",
    props:{
        infoData:{
            type:Object
        },
        taskKey:{
            type:String
        }
    },
    data(){
        return {
            audits:[],
            auditModal:{
              info:null,
              visible:false
            },
          auditUserName:'',
          auditUserSignPicture:false
        }
    },
    watch:{
        infoData(){
            this.setValue();
        }
    },
    mounted(){
       this.setValue();
    },
    methods:{
        setValue(){
            if(this.infoData.auditRecords){
                var f = this.infoData.auditRecords.filter(a=>a.taskKey==this.taskKey);
                if(f.length>0){
                  this.auditUserName = f[0].auditUserName;
                  this.auditUserSignPicture = f[0].auditUserSignPicture;
                }
            }
        },
        showaudit(){
            this.auditModal.info=this.infoData
            this.auditModal.visible=true;
        },
        auditCallBack(d){
            this.auditModal.visible=false;
            this.$emit("callback",d);
        },
    }
}
</script>