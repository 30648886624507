














import { computed } from '../../../../../adapter-vue';
import Icon from '../../../../common/Icon.vue';
import playIcon from '../../../../../assets/icon/video-play.png';
import { IVideoMessageContent } from '../../../../../interface';
interface IProps {
  messageContent: Record<string, unknown> | IVideoMessageContent | undefined;
}
const __sfc_main = {};
__sfc_main.props = {
  messageContent: {
    key: "messageContent",
    required: false,
    type: [Object, null],
    default: () => ({} as IVideoMessageContent)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const videoUrl = computed<string>(() => {
    return (props.messageContent as IVideoMessageContent).snapshotUrl || (props.messageContent as IVideoMessageContent).url;
  });
  return {
    playIcon,
    videoUrl
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
