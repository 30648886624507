import Login from './Login';
import Home from './Home';

const messages = {
  zh_cn: {
    当前语言: '简体中文',
    即时通讯: '即时通讯',
    即时通讯IM: '即时通讯IM',
    社交娱乐: '社交娱乐',
    腾讯云: '腾讯云',
    使用指引: '使用指引',
    Login,
    Home,
  },
};

export default messages;
