import request from '@/request';
const part_url = '/uas/login/';
export default {
    /**
     * 用户登录
     * @param {*} params {"name":"","password":""}
     * @returns {*}
     */
    login(data, token) {
        let url = part_url + "check";
        let param = {
            name: data.name
        }
        if (token) {
            param.Authentication = token;
        }
        return request.post(url, param, data);
    },
    /**
     * 用户退出系统
     */
    logout() {
        let url =  part_url +"logout";
        return request.get(url);
    },
    /**
     * 用户是否登录
     */
    isLogged() {
        let url = part_url + "isLogged";
        return request.get(url);
    },
    refreshToken() {
        let url = "/refreshtoken";
        return request.get(url);
    },
}