export const wordsList = [
  {
    value: '在吗？在吗？在吗？重要的话说三遍。',
  },
  {
    value: '好久没聊天了，快来和我说说话～',
  },
  {
    value: '好的，就这么说定了。',
  },
  {
    value: '感恩的心，感谢有你。',
  },
  {
    value: '糟糕！是心动的感觉！',
  },
  {
    value: '心疼地抱抱自己，我太难了！',
  },
  {
    value: '没关系，别在意，事情过去就过去了。',
  },
  {
    value: '早上好，今天也是让人期待的一天呢！',
  },
  {
    value: '熬夜有什么用，又没人陪你聊天，早点休息吧。',
  },
];
