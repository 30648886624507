




























































import { TUITranslateService, TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import TUICore, { TUIConstants } from '@tencentcloud/tui-core';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { isPC } from '../../../utils/env';
import Icon from '../../common/Icon.vue';
import addSVG from '../../../assets/icon/add.svg';
import backSVG from '../../../assets/icon/back.svg';
import { ref, watch } from '../../../adapter-vue';
import { IContactSearchResult } from '../../../interface';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const searchingPlaceholder = TUITranslateService.t('TUIContact.输入ID');
  const isSearching = ref<boolean>(false);
  const searchValue = ref<string>('');
  const searchResult = ref<IContactSearchResult>({
    user: {
      label: '联系人',
      list: []
    },
    group: {
      label: '群聊',
      list: []
    }
  });
  const handleSearchingHeaderClicked = () => {
    if (!isSearching.value) {
      isSearching.value = true;
    }
  };
  const handleSearchingIconClicked = () => {
    isSearching.value = !isSearching.value;
  };
  const search = async () => {
    if (!searchValue.value) {
      return;
    }
    TUICore.callService({
      serviceName: TUIConstants.TUISearch.SERVICE.NAME,
      method: TUIConstants.TUISearch.SERVICE.METHOD.SEARCH_USER,
      params: {
        userID: searchValue.value
      }
    }).then((res: any) => {
      searchResult.value.user.list = res.data;
    }).catch((error: any) => {
      searchResult.value.user.list = [];
      console.warn('search user error', error);
    });
    TUICore.callService({
      serviceName: TUIConstants.TUISearch.SERVICE.NAME,
      method: TUIConstants.TUISearch.SERVICE.METHOD.SEARCH_GROUP,
      params: {
        groupID: searchValue.value
      }
    }).then((res: any) => {
      searchResult.value.group.list = [res.data.group];
    }).catch((error: any) => {
      searchResult.value.group.list = [];
      console.warn('search group error', error);
    });
  };
  watch(() => searchResult.value, () => {
    TUIStore.update(StoreName.CUSTOM, 'currentContactSearchResult', searchResult.value);
  }, {
    deep: true,
    immediate: true
  });
  watch(() => isSearching.value, () => {
    TUIStore.update(StoreName.CUSTOM, 'currentContactSearchingStatus', isSearching.value);
    if (isSearching.value) {
      searchValue.value = '';
      searchResult.value.user.list = [];
      searchResult.value.group.list = [];
    }
  }, {
    deep: true,
    immediate: true
  });
  // 全局公共方法
  TUIGlobal.updateContactSearch = search;
  TUIGlobal.closeSearching = () => {
    isSearching.value = false;
  };
  return {
    TUITranslateService,
    isPC,
    addSVG,
    backSVG,
    searchingPlaceholder,
    isSearching,
    searchValue,
    handleSearchingHeaderClicked,
    handleSearchingIconClicked,
    search
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
