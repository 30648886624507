
























































































































































import TUIChatEngine, { TUIGlobal, TUITranslateService, TUIUserService, TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import { TUILogin } from '@tencentcloud/tui-core';
import { ref, watch, nextTick, defineProps, defineEmits, defineExpose, onMounted } from '../TUIKit/adapter-vue';
import { Toast, TOAST_TYPE } from '../TUIKit/components/common/Toast/index';
import BottomPopup from '../TUIKit/components/common/BottomPopup/index.vue';
import Icon from '../TUIKit/components/common/Icon.vue';
import rightArrowIcon from '../TUIKit/assets/icon/right-icon.svg';
import selectedIcon from '../TUIKit/assets/icon/selected.svg';
import { IUserProfile } from '../TUIKit/interface';
import router from '../router/index';
const __sfc_main = {};
__sfc_main.props = {
  displayType: {
    type: String,
    default: 'profile' // "profile"/"setting"/"all"
  },
  showSetting: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const settingDomRef = ref();
  const userProfile = ref<IUserProfile>({});
  const isPC = ref(TUIGlobal.getPlatform() === 'pc');
  const settingList = ref<{
    [propsName: string]: {
      value: string;
      label: string;
      onClick?: any;
      // children相关
      selectedChild?: string;
      childrenShowType?: string; // "bottomPopup"/"switch"
      showChildren?: boolean;
      children?: {
        [propsName: string]: {
          value: string;
          label: string;
          onClick?: any;
        };
      };
    };
  }>({
    editProfile: {
      value: 'editProfile',
      label: '编辑资料（暂未开放）',
      onClick: (item: any) => {
        console.warn('编辑资料功能努力开发中，敬请期待');
      }
    },
    allowType: {
      value: 'allowType',
      label: '加我为好友时',
      selectedChild: '',
      childrenShowType: 'bottomPopup',
      showChildren: false,
      onClick: (item: any) => {
        if (!isPC.value) {
          item.showChildren = true;
        }
      },
      children: {
        [TUIChatEngine.TYPES.ALLOW_TYPE_ALLOW_ANY]: {
          value: TUIChatEngine.TYPES.ALLOW_TYPE_ALLOW_ANY,
          label: '同意任何用户加好友',
          onClick: (item: any) => {
            updateMyProfile({
              allowType: item.value
            });
          }
        },
        [TUIChatEngine.TYPES.ALLOW_TYPE_NEED_CONFIRM]: {
          value: TUIChatEngine.TYPES.ALLOW_TYPE_NEED_CONFIRM,
          label: '需要验证',
          onClick: (item: any) => {
            updateMyProfile({
              allowType: item.value
            });
          }
        },
        [TUIChatEngine.TYPES.ALLOW_TYPE_DENY_ANY]: {
          value: TUIChatEngine.TYPES.ALLOW_TYPE_DENY_ANY,
          label: '拒绝任何人加好友',
          onClick: (item: any) => {
            updateMyProfile({
              allowType: item.value
            });
          }
        }
      }
    },
    exit: {
      value: 'exit',
      label: '退出登录',
      onClick: (item: any) => {
        TUILogin.logout().then(() => {
          router.push({
            path: '/'
          });
        });
      }
    }
  });
  const handleSettingListItemOnClick = (item: any) => {
    if (item?.onClick && typeof item?.onClick === 'function') {
      item.onClick(item);
    }
  };
  const updateMyProfile = (props: object) => {
    TUIUserService.updateMyProfile(props).then((res: any) => {
      Toast({
        message: '更新用户资料成功',
        type: TOAST_TYPE.SUCCESS
      });
    }).catch((err: any) => {
      console.warn('更新用户资料失败', err);
      Toast({
        message: '更新用户资料失败',
        type: TOAST_TYPE.ERROR
      });
    });
  };
  TUIStore.watch(StoreName.USER, {
    userProfile: (userProfileData: IUserProfile) => {
      userProfile.value = userProfileData;
      if (userProfile?.value?.allowType) {
        settingList.value.allowType.selectedChild = userProfile?.value?.allowType;
      }
    }
  });

  // pc端
  // 处理 setting 点击外部关闭
  // click outside
  let clickOutside = false;
  let clickInner = false;
  const onClickOutside = (component: any) => {
    document.addEventListener('mousedown', onClickDocument);
    component?.addEventListener && component?.addEventListener('mousedown', onClickTarget);
  };
  const onClickDocument = () => {
    clickOutside = true;
    if (!clickInner && clickOutside) {
      emits('update:showSetting', false);
      removeClickListener(settingDomRef.value);
    }
    clickOutside = false;
    clickInner = false;
  };
  const onClickTarget = () => {
    clickInner = true;
  };
  const removeClickListener = (component: any) => {
    document.removeEventListener('mousedown', onClickDocument);
    component?.removeEventListener && component?.removeEventListener('mousedown', onClickTarget);
  };
  const getUserinfo = () => {
    // 查询自己的资料
    TUIUserService.getUserProfile().then((res: any) => {
      userProfile.value = res.data;
    });
  };
  watch(() => props.showSetting, (newVal: boolean, oldVal: boolean) => {
    if (isPC.value && newVal && !oldVal) {
      nextTick(() => {
        onClickOutside(settingDomRef.value);
      });
    }
  }, {
    immediate: true
  });

  // 规避TUIStore.watch userProfile 登录后暂时不能及时触发更新
  onMounted(() => {
    // // 查询自己的资料
    // TUIUserService.getUserProfile().then((res: any) => {
    //   userProfile.value = res.data
    // })
  });
  return Object.assign({
    TUITranslateService,
    rightArrowIcon,
    selectedIcon,
    settingDomRef,
    userProfile,
    isPC,
    settingList,
    handleSettingListItemOnClick
  }, {
    getUserinfo
  });
};
__sfc_main.components = Object.assign({
  Icon,
  BottomPopup
}, __sfc_main.components);
export default __sfc_main;
