























































































































































































import { TUIGlobal, TUIStore, StoreName, TUITranslateService, TUIUserService } from '@tencentcloud/chat-uikit-engine';
import { TUIChat, TUIConversation, TUIContact, TUIGroup, TUISearch } from '../TUIKit';
import { ref, onMounted } from '../TUIKit/adapter-vue';
import Header from '../components/Header.vue';
import Menu from '../components/Menu.vue';
import Profile from './Profile.vue';
import { TUILogin } from '@tencentcloud/tui-core';
import commons from '@/utils/commons.js';
import im from '@/api/system/im';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isPC = ref(TUIGlobal.getPlatform() === 'pc');
  const currentTab = ref('message');
  const currentConversationID = ref('');
  const showMore = ref(false);
  const showCall = ref<boolean>(false);
  const isMinimized = ref<boolean>(false);
  const dragRef = ref();
  // const SDKAppID = 1600022494 // Your SDKAppID
  const SDKAppID = 1600031761; // Your SDKAppID
  const userID = localStorage.getItem(commons.User.userName); // User ID
  const RefProfile = ref();
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: (id: string) => {
      currentConversationID.value = id;
    }
  });
  const navList = [{
    icon: 'message',
    name: 'message',
    label: '消息'
  }, {
    icon: 'relation',
    name: 'relation',
    label: '通讯录'
  }, {
    icon: 'profile',
    name: 'profile',
    label: '个人中心'
  }];
  const selectModel = (modelName: string) => {
    currentTab.value = modelName;
  };
  const closeChat = (closedConversationID: string) => {
    console.log(`conversation ${closedConversationID} has been closed.`);
  };
  const openShowMore = () => {
    showMore.value = !showMore.value;
  };

  // beforeCalling：在拨打电话前与收到通话邀请前执行
  const beforeCalling = () => {
    showCall.value = true;
  };
  // afterCalling：结束通话后执行
  const afterCalling = () => {
    showCall.value = false;
    isMinimized.value = false;
  };
  // onMinimized：组件切换最小化状态时执行
  const onMinimized = (oldMinimizedStatus: boolean, newMinimizedStatus: boolean) => {
    isMinimized.value = newMinimizedStatus;
    dragRef?.value?.positionReset && dragRef?.value?.positionReset();
  };
  onMounted(() => {
    im.getUserSig().then(res => {
      console.log(SDKAppID);
      console.log(userID);
      TUILogin.login({
        SDKAppID,
        userID,
        // UserSig 是用户登录即时通讯 IM 的密码，其本质是对 UserID 等信息加密后得到的密文。
        // 该方法仅适合本地跑通 Demo 和功能调试，详情请参见 https://cloud.tencent.com/document/product/269/32688
        userSig: res.data,
        // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true
        useUploadPlugin: true,
        // 本地审核可识别、处理不安全、不适宜的内容，为您的产品体验和业务安全保驾护航
        // 此功能为增值服务，请参考：https://cloud.tencent.com/document/product/269/79139
        // 如果您已购买内容审核服务，开启此功能请设置为 true
        useProfanityFilterPlugin: false,
        framework: 'vue2'
      }).then(res => {
        RefProfile.value.getUserinfo();
        console.log(res);
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });
  });
  return {
    TUITranslateService,
    isPC,
    currentTab,
    currentConversationID,
    showMore,
    RefProfile,
    navList,
    selectModel,
    closeChat,
    openShowMore
  };
};
__sfc_main.components = Object.assign({
  Menu,
  Profile,
  TUISearch,
  TUIConversation,
  TUIChat,
  TUIGroup,
  TUIContact
}, __sfc_main.components);
export default __sfc_main;
