<template>
  <span>{{ dateShow }}</span>
</template>
<script>
export default {
  name: "auditDate",
  props: {
    infoData: {
      type: Object,
    },
    taskKey: {
      type: String,
    },
  },
  data() {
    return {
      audits: [],
      auditModal: {
        info: null,
        visible: false,
      },
      dateShow: "",
    };
  },
  watch: {
    infoData() {
      this.setValue();
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      if (this.infoData.auditRecords) {
        var f = this.infoData.auditRecords.filter(
          (a) => a.taskKey == this.taskKey
        );
        if (f.length > 0) {
          this.audits = f;
          this.dateShow = this.$moment(f[0].time, "YYYY-MM-DD")
            .format("YYYY年MM月DD日")
            .toString();
        }
      }
    },
    showaudit() {
      this.auditModal.info = this.infoData;
      this.auditModal.visible = true;
    },
    auditCallBack(d) {
      this.auditModal.visible = false;
      this.$emit("callback", d != null);
    },
  },
};
</script>