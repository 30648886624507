import Vue from 'vue'
import Vuex from 'vuex'
import currentUser from "./modules/currentUser"
import systemMenu from "./modules/systemMenu"
import permission from "./modules/permission"
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        currentUser,
        systemMenu,
        permission
    },
    strict: "debug",
});

export default store;