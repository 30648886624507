











































import TUIChatEngine, { TUIStore, StoreName, TUIGroupService, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { ref, watch } from '../../../../adapter-vue';
import { isPC, isH5 } from '../../../../utils/env';
import BottomPopup from '../../../common/BottomPopup/index.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const MessageInputAt = ref();
  const memberListItems = ref();
  const showAtList = ref(false);
  const memberList = ref<Array<any>>();
  const allMemberList = ref<Array<any>>();
  const showMemberList = ref<Array<any>>();
  const isGroup = ref(false);
  const position = ref({
    left: 0,
    top: 0
  });
  const selectedIndex = ref(0);
  const currentConversationID = ref('');
  const all = {
    userID: TUIChatEngine.TYPES.MSG_AT_ALL,
    nick: '所有人',
    isAll: true,
    avatar: 'https://web.sdk.qcloud.com/im/assets/images/at.svg'
  };
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: (id: string) => {
      if (id !== currentConversationID.value) {
        currentConversationID.value = id;
        memberList.value = [];
        allMemberList.value = [];
        showMemberList.value = [];
        isGroup.value = false;
        TUIStore.update(StoreName.CUSTOM, 'memberList', memberList.value);
        if (currentConversationID?.value?.startsWith('GROUP')) {
          isGroup.value = true;
          const groupID = currentConversationID?.value?.substring(5);
          TUIGroupService.switchGroup(groupID);
        } else {
          TUIGroupService.switchGroup('');
        }
      }
    }
  });
  TUIStore.watch(StoreName.GRP, {
    currentGroupMemberList: (list: Array<any>) => {
      memberList.value = list;
      allMemberList.value = [all, ...memberList.value];
      showMemberList.value = allMemberList.value;
      TUIStore.update(StoreName.CUSTOM, 'memberList', memberList.value);
    }
  });
  const toggleAtList = (show: boolean) => {
    if (!isGroup.value) {
      return;
    }
    showAtList.value = show;
    if (showAtList.value) {
      emits('onAtListOpen');
    }
  };
  const handleAtListPosition = (positionData: {
    top: number;
    left: number;
  }) => {
    position.value = positionData;
  };
  const setCurrentSelectIndex = (index: any) => {
    selectedIndex.value = index;
    memberListItems.value?.[selectedIndex.value]?.scrollIntoView(false);
  };
  const setShowMemberList = (list: any) => {
    showMemberList.value = list;
  };
  TUIGlobal.toggleAtList = toggleAtList;
  TUIGlobal.handleAtListPosition = handleAtListPosition;
  TUIGlobal.setCurrentSelectIndex = setCurrentSelectIndex;
  TUIGlobal.setShowMemberList = setShowMemberList;
  watch(() => [position.value, MessageInputAt?.value], () => {
    if (isH5 || !MessageInputAt?.value || !MessageInputAt?.value?.style) {
      return;
    }
    MessageInputAt.value.style.left = position.value.left + 'px';
    MessageInputAt.value.style.top = position.value.top - MessageInputAt.value.clientHeight + 'px';
  });
  const closeAt = () => {
    showAtList.value = false;
    showMemberList.value = allMemberList.value;
    position.value = {
      left: 0,
      top: 0
    };
  };
  const selectItem = (index: number) => {
    if (isPC && TUIGlobal.selectItem) {
      TUIGlobal.selectItem(index);
    } else {
      if (showMemberList?.value?.length) {
        const item = showMemberList?.value[index];
        emits('insertAt', {
          id: (item as any)?.userID,
          label: (item as any)?.nick || (item as any)?.userID
        });
      }
    }
    closeAt();
  };
  const handleMemberAvatar = (item: any) => {
    return (item as any)?.avatar || 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png';
  };
  const handleMemberName = (item: any) => {
    return (item as any)?.nick ? (item as any)?.nick : (item as any)?.userID;
  };
  return Object.assign({
    TUITranslateService,
    isPC,
    MessageInputAt,
    memberListItems,
    showAtList,
    showMemberList,
    selectedIndex,
    closeAt,
    selectItem,
    handleMemberAvatar,
    handleMemberName
  }, {
    toggleAtList
  });
};
__sfc_main.components = Object.assign({
  BottomPopup
}, __sfc_main.components);
export default __sfc_main;
