
































































































import { computed, toRefs } from '../../../../adapter-vue';
import TUIChatEngine, { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import ReadStatus from './read-status/index.vue';
import MessageQuote from './message-quote/index.vue';
import Avatar from '../../../common/Avatar/index.vue';
import MessageTranslate from './message-translate/index.vue';
import loadingIcon from '../../../../assets/icon/loading.png';
import { shallowCopyMessage } from '../../utils/utils';
import { isPC } from '../../../../utils/env';
interface IProps {
  messageItem: IMessageModel;
  content?: any;
  blinkMessageIDList?: string[];
  classNameList?: string[];
}
interface IEmits {
  (e: 'resendMessage'): void;
  (e: 'blinkMessage', messageID: string): void;
  (e: 'setReadReceiptPanelVisible', visible: boolean, message?: IMessageModel): void;
  // 下面的方法是 uniapp 专属
  (e: 'scrollTo', scrollHeight: number): void;
}
const __sfc_main = {};
__sfc_main.props = {
  messageItem: {
    key: "messageItem",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  },
  content: {
    key: "content",
    required: false,
    type: null,
    default: () => ({})
  },
  blinkMessageIDList: {
    key: "blinkMessageIDList",
    required: false,
    type: Array,
    default: () => []
  },
  classNameList: {
    key: "classNameList",
    required: false,
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const TYPES = TUIChatEngine.TYPES;
  const riskImageReplaceUrl = 'https://web.sdk.qcloud.com/component/TUIKit/assets/has_risk_default.png';
  const needLoadingIconMessageType = [TYPES.MSG_LOCATION, TYPES.MSG_TEXT, TYPES.MSG_CUSTOM, TYPES.MSG_MERGER, TYPES.MSG_FACE];
  const {
    blinkMessageIDList,
    messageItem: message
  } = toRefs(props);
  const containerClassNameList = computed(() => {
    return ['message-bubble', ...props.classNameList];
  });

  // 当表情删除时，reactionList 会更新对应表情中 totalUserCount
  const hasEmojiReaction = computed(() => {
    return message.value?.reactionList.some((item: any) => item.totalUserCount !== 0);
  });
  const isNoPadding = computed(() => {
    return !hasEmojiReaction.value && [TYPES.MSG_IMAGE, TYPES.MSG_VIDEO].includes(message.value.type);
  });
  const riskContentText = computed<string>(() => {
    let content = TUITranslateService.t('TUIChat.涉及敏感内容') + ', ';
    if (message.value.flow === 'out') {
      content += TUITranslateService.t('TUIChat.发送失败');
    } else {
      content += TUITranslateService.t(message.value.type === TYPES.MSG_AUDIO ? 'TUIChat.无法收听' : 'TUIChat.无法查看');
    }
    return content;
  });
  const isBlink = computed(() => {
    if (message.value?.ID) {
      return blinkMessageIDList?.value?.includes(message.value.ID);
    }
    return false;
  });
  function resendMessage() {
    if (!message.value?.hasRiskContent) {
      emits('resendMessage');
    }
  }
  function blinkMessage(messageID: string) {
    emits('blinkMessage', messageID);
  }
  function scrollTo(scrollHeight: number) {
    emits('scrollTo', scrollHeight);
  }
  function openReadUserPanel() {
    emits('setReadReceiptPanelVisible', true, message.value);
  }
  return {
    loadingIcon,
    shallowCopyMessage,
    isPC,
    props,
    TYPES,
    riskImageReplaceUrl,
    needLoadingIconMessageType,
    message,
    containerClassNameList,
    isNoPadding,
    riskContentText,
    isBlink,
    resendMessage,
    blinkMessage,
    scrollTo,
    openReadUserPanel
  };
};
__sfc_main.components = Object.assign({
  Avatar,
  Icon,
  ReadStatus,
  MessageTranslate,
  MessageQuote
}, __sfc_main.components);
export default __sfc_main;
