  <template>
  <a-button
    :type="type"
    :block="block"
    :size="size"
    :class="classThis"
    :shape="shape"
    :icon="icon"
    v-if="!dis"
    :loading="loading"
    :htmlType="htmlType"
    @click="clickHandler"
    >{{ name }}</a-button
  >
</template>

<script>
export default {
  props: {
    name: String,
    size: String,
    type: String,
    icon: String,
    classThis: String,
    shape: String,
    htmlType: {
      type: String,
      default: "button",
    },
    roles: {
      type: Array,
      default: () => [],
    },
    allInRole: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dis: this.disabled,
    };
  },
  mounted() {
    var btnA = this.roles; //获取当前按钮权限
    if (btnA.length > 0) {
      var auth = this.$store.getters["currentUser/getAuthoritys"];
      this.dis=true;
      if(auth!=null){
        var userA = auth.split(","); //获取本地个人权限
        var isPass = 0;
        for (var i = 0; i < btnA.length; i++) {
          if (userA.indexOf(btnA[i]) > -1) isPass++; //判断按钮是否用户的权限
        }
        // 用户必须拥有所有操作权限
        if (this.allInRole) {
          this.dis = isPass != btnA.length;
        }else{
          this.dis=isPass==0;
        }
      }
      
    } else {
      //默认有权限
      this.dis = false;
    }
  },
  watch: {
    disabled(val) {
      this.dis = val;
    },
  },
  methods: {
    clickHandler() {
      this.$emit("click"); // 触发 `vclick` 事件
    },
  },
};
</script>