








import { withDefaults, computed } from '../../../../../adapter-vue';
import { IImageMessageContent } from '../../../../../interface';
interface IProps {
  messageContent: Record<string, unknown> | IImageMessageContent | undefined;
}
const __sfc_main = {};
__sfc_main.props = {
  messageContent: {
    key: "messageContent",
    required: false,
    type: [Object, null],
    default: () => ({} as IImageMessageContent)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const imageUrl = computed<string>(() => (props.messageContent as IImageMessageContent).url || '');
  return {
    imageUrl
  };
};
export default __sfc_main;
