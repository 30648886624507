

























import { ref, computed, withDefaults } from '../../../../../adapter-vue';
import { IHighlightContent } from '../../../type';
interface IProps {
  contentText: Array<IHighlightContent>;
  messageContent: Record<string, unknown> | undefined;
  displayType: 'bubble' | 'info';
}
const __sfc_main = {};
__sfc_main.props = {
  contentText: {
    key: "contentText",
    required: false,
    type: Array,
    default: () => ([] as Array<IHighlightContent>)
  },
  messageContent: {
    key: "messageContent",
    required: false,
    type: [Object, null],
    default: () => ({} as Record<string, unknown>)
  },
  displayType: {
    key: "displayType",
    required: false,
    type: String,
    default: 'bubble'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const contentText = ref<Array<{
    text: string;
    isHighlight: boolean;
  }>>(props.contentText);
  const typeIcon = computed(() => {
    const fileUrl = (props?.messageContent?.url as string);
    const index = fileUrl?.lastIndexOf('.');
    const type = fileUrl?.substring(index + 1);
    return handleFileIconForShow(type);
  });
  const fileSize = computed(() => props?.messageContent?.size);
  const handleFileIconForShow = (type: string) => {
    const urlBase = 'https://web.sdk.qcloud.com/component/TUIKit/assets/file-';
    const fileTypes = ['image', 'pdf', 'text', 'ppt', 'presentation', 'sheet', 'zip', 'word', 'video', 'unknown'];
    let url = '';
    let iconType = '';
    fileTypes?.forEach((typeName: string) => {
      if (type?.includes(typeName)) {
        url = urlBase + typeName + '.svg';
        iconType = typeName;
      }
    });
    return {
      iconSrc: url ? url : urlBase + 'unknown.svg',
      iconType: iconType ? iconType : 'unknown'
    };
  };
  return {
    contentText,
    typeIcon,
    fileSize
  };
};
export default __sfc_main;
