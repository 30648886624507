


















import { watchEffect, ref } from '../../../../adapter-vue';
import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
const __sfc_main = {};
__sfc_main.props = {
  isEdit: {
    type: Boolean,
    default: () => false
  },
  messageItem: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const message = ref<IMessageModel>();
  const isEditMsg = ref(false);
  const emits = __ctx.emit;
  watchEffect(() => {
    message.value = props.messageItem;
    isEditMsg.value = props.isEdit;
  });
  const messageEdit = () => {
    emits('messageEdit');
  };
  return {
    TUITranslateService,
    message,
    isEditMsg,
    messageEdit
  };
};
export default __sfc_main;
