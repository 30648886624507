















import { computed } from '../../../../../adapter-vue';
import { TUIStore, StoreName, IMessageModel, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { getBoundingClientRect, getScrollInfo } from '@tencentcloud/universal-api';
import { isUniFrameWork } from '../../../../../utils/env';
import { Toast, TOAST_TYPE } from '../../../../../components/common/Toast/index';
import type { ICloudCustomData, IQuoteContent } from './interface.ts';
import { decodeTextMessage } from '../../../utils/emojiList';
export interface IProps {
  message: IMessageModel;
}
export interface IEmits {
  (e: 'scrollTo', scrollHeight: number): void;
  (e: 'blinkMessage', messageID: string | undefined): void;
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  let selfAddValue = 0;
  let messageList: IMessageModel[] = [];
  TUIStore.watch(StoreName.CHAT, {
    messageList(list: IMessageModel[]) {
      messageList = list;
    }
  });
  const quoteContent = computed<IQuoteContent | undefined>(() => {
    try {
      const cloudCustomData: ICloudCustomData = JSON.parse(props.message?.cloudCustomData || '{}');
      return cloudCustomData.messageReply;
    } catch (error) {
      return undefined;
    }
  });
  async function scrollToOriginalMessage() {
    const originMessageID = quoteContent.value?.messageID;
    const isOriginalMessageInScreen = messageList.some(msg => msg.ID === originMessageID);
    if (originMessageID && isOriginalMessageInScreen) {
      try {
        const scrollViewRect = await getBoundingClientRect('#messageScrollList', 'messageList');
        const originalMessageRect = await getBoundingClientRect('#tui-' + originMessageID, 'messageList');
        const {
          scrollTop
        } = await getScrollInfo('#messageScrollList', 'messageList');
        const finalScrollTop = originalMessageRect.top + scrollTop - scrollViewRect.top - selfAddValue++ % 2;
        const isNeedScroll = originalMessageRect.top < scrollViewRect.top;
        if (!isUniFrameWork && window) {
          const scrollView = document.getElementById('messageScrollList');
          if (isNeedScroll && scrollView) {
            scrollView.scrollTop = finalScrollTop;
          }
        } else if (isUniFrameWork && isNeedScroll) {
          emits('scrollTo', finalScrollTop);
        }
        emits('blinkMessage', originMessageID);
      } catch (error) {
        console.error(error);
      }
    } else {
      Toast({
        message: TUITranslateService.t('TUIChat.无法定位到原消息'),
        type: TOAST_TYPE.WARNING
      });
    }
  }
  return {
    decodeTextMessage,
    quoteContent,
    scrollToOriginalMessage
  };
};
export default __sfc_main;
