



































































































import { ref, onMounted, watch, nextTick } from '../../../../adapter-vue';
import { IMessageModel, TUIStore, TUIChatService, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import closeIcon from '../../../../assets/icon/icon-close.svg';
import Icon from '../../../common/Icon.vue';
import Overlay from '../../../common/Overlay/index.vue';
import Avatar from '../../../common/Avatar/index.vue';
import FetchMore from '../../../common/FetchMore/index.vue';
import type { IGroupMessageReadMemberData, IMemberData, ITabInfo, TabName } from './interface';
import { isMobile } from '../../../../utils/env';
type ReadType = 'unread' | 'read' | 'all';
interface IProps {
  message: IMessageModel;
}
interface IEmits {
  (key: 'setReadReceiptPanelVisible', visible: boolean, message?: IMessageModel): void;
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  let lastUnreadCursor: string = '';
  let lastReadCursor: string = '';
  const tabNameList: TabName[] = ['unread', 'read'];
  const isListFetchCompleted: Record<TabName, boolean> = {
    unread: false,
    read: false,
    close: false
  };
  const isPullDownFetching = ref<boolean>(false);
  const isPanelClose = ref<boolean>(false);
  const isFirstLoadFinished = ref<boolean>(false);
  const isStopFetchMore = ref<boolean>(false);
  const currentTabName = ref<TabName>('unread');
  const tabInfo = ref<ITabInfo>(generateInitalTabInfo());
  onMounted(async () => {
    await initAndRefetchReceiptInfomation();
    nextTick(() => {
      isFirstLoadFinished.value = true;
    });
  });
  watch(
  // uniapp下监听不到数据变化
  () => props.message.readReceiptInfo.readCount, () => {
    initAndRefetchReceiptInfomation();
  });
  async function fetchGroupMessageRecriptMemberListByType(readType: ReadType = 'all') {
    const message = TUIStore.getMessageModel(props.message.ID);
    let unreadResult = ({} as IGroupMessageReadMemberData);
    let readResult = ({} as IGroupMessageReadMemberData);
    if (readType === 'all' || readType === 'unread') {
      unreadResult = await TUIChatService.getGroupMessageReadMemberList({
        message,
        filter: 1,
        cursor: lastUnreadCursor,
        count: 100
      });
      if (unreadResult) {
        lastUnreadCursor = unreadResult.data.cursor;
        if (unreadResult.data.isCompleted) {
          isListFetchCompleted.unread = true;
        }
      }
    }
    if (readType === 'all' || readType === 'read') {
      readResult = await TUIChatService.getGroupMessageReadMemberList({
        message,
        filter: 0,
        cursor: lastReadCursor,
        count: 100
      });
      if (readResult) {
        lastReadCursor = readResult.data.cursor;
        if (readResult.data.isCompleted) {
          isListFetchCompleted.read = true;
        }
      }
    }

    // Fetch the total number of read and unread users
    const {
      unreadCount: totalUnreadCount,
      readCount: totalReadCount
    } = message.readReceiptInfo;
    return {
      unreadResult: {
        count: totalUnreadCount,
        ...unreadResult.data
      },
      readResult: {
        count: totalReadCount,
        ...readResult.data
      }
    };
  }
  async function pullDownFetchMoreData() {
    /**
     * 使用 isPullDownFetching 控制 FetchMore 组件的状态
     * 顺便同时做 uniapp 下的 intersectionObserver 的加锁
     * 因为 uniapp 下 没有 isIntersecting 无法判断被观察的元素进入还是退出观察区
     */
    if (isListFetchCompleted[currentTabName.value] || isPullDownFetching.value) {
      return;
    }
    isPullDownFetching.value = true;
    if (currentTabName.value === 'unread' || currentTabName.value === 'read') {
      const {
        unreadResult,
        readResult
      } = await fetchGroupMessageRecriptMemberListByType(currentTabName.value);
      checkStopFetchMore();
      try {
        tabInfo.value.unread.memberList = tabInfo.value.unread.memberList.concat(unreadResult.unreadUserInfoList || []);
        tabInfo.value.read.memberList = tabInfo.value.read.memberList.concat(readResult.readUserInfoList || []);
      } finally {
        isPullDownFetching.value = false;
      }
    }
  }

  /**
   * Initializes and refetches receipt information.
   *
   * @return {Promise<void>} A promise that resolves when the function has completed.
   */
  async function initAndRefetchReceiptInfomation(): Promise<void> {
    lastUnreadCursor = '';
    lastReadCursor = '';
    isStopFetchMore.value = false;
    isListFetchCompleted.unread = false;
    isListFetchCompleted.read = false;
    const {
      unreadResult,
      readResult
    } = await fetchGroupMessageRecriptMemberListByType('all');
    checkStopFetchMore();
    resetTabInfo('read', readResult.count, readResult.readUserInfoList);
    resetTabInfo('unread', unreadResult.count, unreadResult.unreadUserInfoList);
    resetTabInfo('close');
  }

  /**
   * Checks if the fetch more operation should be stopped
   * by IntersetctionObserver.disconnect().
   *
   * @return {void}
   */
  function checkStopFetchMore(): void {
    if (isListFetchCompleted.read && isListFetchCompleted.unread) {
      isStopFetchMore.value = true;
    }
  }

  /**
   * Resets the information of a specific tab.
   *
   * @param {TabName} tabName - The name of the tab to reset.
   * @param {number} [count] - The count to assign to the tab. Optional.
   * @param {IMemberData[]} [memberList] - The list of members to assign to the tab. Optional.
   * @return {void} - This function does not return anything.
   */
  function resetTabInfo(tabName: TabName, count?: number, memberList?: IMemberData[]): void {
    tabInfo.value[tabName].count = count;
    tabInfo.value[tabName].memberList = memberList || [];
  }

  /**
   * Generates the initial tab information.
   *
   * @return {ITabInfo} The initial tab information.
   */
  function generateInitalTabInfo(): ITabInfo {
    return {
      read: {
        tabName: TUITranslateService.t('TUIChat.已读'),
        count: undefined,
        memberList: []
      },
      unread: {
        tabName: TUITranslateService.t('TUIChat.未读'),
        count: undefined,
        memberList: []
      },
      close: {
        tabName: TUITranslateService.t('TUIChat.关闭'),
        count: undefined,
        memberList: []
      }
    };
  }

  /**
   * Toggles the tab name.
   *
   * @param {TabName} tabName - The name of the tab to toggle.
   * @return {void} This function does not return anything.
   */
  function toggleTabName(tabName: TabName): void {
    currentTabName.value = tabName;
  }
  function closeReadReceiptPanel(): void {
    isPanelClose.value = true;
    setTimeout(() => {
      emits('setReadReceiptPanelVisible', false);
    }, 200);
  }
  return {
    TUITranslateService,
    closeIcon,
    isMobile,
    tabNameList,
    isPullDownFetching,
    isPanelClose,
    isFirstLoadFinished,
    isStopFetchMore,
    currentTabName,
    tabInfo,
    pullDownFetchMoreData,
    toggleTabName,
    closeReadReceiptPanel
  };
};
__sfc_main.components = Object.assign({
  Overlay,
  Icon,
  Avatar,
  FetchMore
}, __sfc_main.components);
export default __sfc_main;
