































































































































import TUIChatEngine, { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { ref, watchEffect, withDefaults } from '../../../../adapter-vue';
import MessageAbstractText from './message-abstract/message-abstract-text.vue';
import MessageAbstractFile from './message-abstract/message-abstract-file.vue';
import MessageAbstractCustom from './message-abstract/message-abstract-custom.vue';
import MessageAbstractImage from './message-abstract/message-abstract-image.vue';
import MessageAbstractVideo from './message-abstract/message-abstract-video.vue';
import { generateSearchResultShowName, generateSearchResultAvatar, generateSearchResultShowContent, generateSearchResultTime, enterConversation } from '../../utils';
import { messageTypeAbstractMap, searchResultItemDisplayTypeValues, searchMessageTypeValues, IHighlightContent } from '../../type';
import { ISearchResultListItem } from '../../../../interface';
import { isPC } from '../../../../utils/env';
interface IProps {
  listItem: IMessageModel | ISearchResultListItem;
  listItemContent?: Record<string, unknown>;
  type: searchMessageTypeValues;
  displayType: searchResultItemDisplayTypeValues;
  keywordList: string[];
}
const __sfc_main = {};
__sfc_main.props = {
  listItem: {
    key: "listItem",
    required: false,
    type: null,
    default: () => ({} as IMessageModel | ISearchResultListItem)
  },
  listItemContent: {
    key: "listItemContent",
    required: false,
    type: Object,
    default: () => ({} as Record<string, unknown>)
  },
  type: {
    key: "type",
    required: false,
    type: null,
    default: 'allMessage'
  },
  displayType: {
    key: "displayType",
    required: false,
    type: null,
    default: 'info'
  },
  keywordList: {
    key: "keywordList",
    required: false,
    type: Array,
    default: () => ([] as string[])
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const TYPES = ref(TUIChatEngine.TYPES);
  const avatarForShow = ref<string>('');
  const nameForShow = ref<string>('');
  const contentForShow = ref<Array<IHighlightContent>>([]);
  const timeForShow = ref<string>('');
  const isHovering = ref<boolean>(false);
  watchEffect(() => {
    avatarForShow.value = generateSearchResultAvatar(props.listItem);
    nameForShow.value = generateSearchResultShowName(props.listItem, props?.listItemContent);
    contentForShow.value = generateSearchResultShowContent(props.listItem, props.type, (props.keywordList as Array<string>), props?.displayType !== 'bubble');
    timeForShow.value = (props.listItem as IMessageModel)?.time ? generateSearchResultTime((props.listItem as IMessageModel)?.time * 1000) : '';
  });
  const onResultItemClicked = () => {
    if (props.type === 'contact' || props.type === 'group') {
      enterConversation((props.listItem as IMessageModel));
    } else {
      if (props.displayType === 'info' && !(props.listItem as IMessageModel)?.ID) {
        emits('showResultDetail', true, props.type, props.listItem);
      } else {
        navigateToChatPosition();
      }
    }
  };
  const setHoverStatus = (status: boolean) => {
    isHovering.value = status;
  };
  const navigateToChatPosition = () => {
    // 定位到指定位置
    emits('navigateToChatPosition', props.listItem);
  };
  const getMessageAbstractType = (message: IMessageModel | ISearchResultListItem) => {
    return message?.type ? TUITranslateService.t(`TUISearch.${messageTypeAbstractMap[message.type]}`) : '';
  };
  return {
    TUITranslateService,
    isPC,
    TYPES,
    avatarForShow,
    nameForShow,
    contentForShow,
    timeForShow,
    isHovering,
    onResultItemClicked,
    setHoverStatus,
    navigateToChatPosition,
    getMessageAbstractType
  };
};
__sfc_main.components = Object.assign({
  MessageAbstractText,
  MessageAbstractFile,
  MessageAbstractCustom,
  MessageAbstractImage,
  MessageAbstractVideo
}, __sfc_main.components);
export default __sfc_main;
