const Home = {
  "欢迎使用": "欢迎使用",
  "建议体验功能": "建议体验功能",
  "用UI组件快速集成": "用UI组件快速集成",
  "创建项目": "创建项目",
  "下载TUIKit组件": "下载 TUIKit 组件",
  "引入TUIKit组件": "引入 TUIKit 组件",
  "获取SDKAppID": "获取 SDKAppID 、密钥与 userID",
  "调用TUIKit组件": "调用 TUIKit 组件",
  "启动项目": "启动项目",
  "发送您的第一条消息": "发送您的第一条消息",
  "首购低至1折": "首购低至1折",
  "IM首购9percent折": " IM 首购低至0.9折",
  "续费9折起": "续费9折起",
  "复购75%起": "复购7.5折起",
  "立即选购": "立即选购",
  "体验更多Demo": "体验更多Demo",
  "立即前往": "立即前往",
  "已完成": "已完成",
  "待体验": "待体验",
  "发送一条消息": "发送一条消息",
  "撤回一条消息": "撤回一条消息",
  "切换一次使用语言": "切换一次使用语言",
  "发起一个群聊": "发起一个群聊",
  "开启一次群禁言": "开启一次群禁言",
  "解散一个群聊": "解散一个群聊",
  "发起一次通话": "发起一次通话",
  "修改一次我的昵称": "修改一次我的昵称",
  "我们为您默认提供了一位“示例好友”和一个“示例客服群”您不用额外添加好友和群聊就可完整体验腾讯云 IM 单聊、群聊的所有功能。": "我们为您默认提供了一位“示例好友”和一个“示例客服群”，您不用额外添加好友和群聊就可完整体验腾讯云 IM 单聊、群聊的所有功能。",
  "随时随地": "随时随地，即“登”即“测”，快试试吧！",
  "男": "男",
  "女": "女",
  "用户ID": "用户ID",
  "个性签名": "个性签名",
  "暂未设置": "暂未设置",
  "出生年月": "出生年月",
  "编辑资料": "编辑资料",
  "关于腾讯云IM": "关于腾讯云IM",
  "退出登录": "退出登录",
  "隐私条例": "隐私条例",
  "用户协议": "用户协议",
  "免责声明": "免责声明",
  "联系我们": "联系我们",
  "注销账户": "注销账户",
  "SDK版本": "SDK版本",
  "注销后，您将无法使用当前账号，相关数据也将删除无法找回。 当前账号": "注销后，您将无法使用当前账号，相关数据也将删除无法找回。 当前账号",
  "IM-免责声明": "IM-免责声明",
  "IM（“本产品”）是由腾讯云提供的一款测试产品，腾讯云享有本产品的著作权和所有权。本产品仅用于功能体验，不得用于任何商业用途。依据相关部门监管要求，严禁在使用中有任何色情、辱骂、暴恐、涉政等违法内容传播。": "IM（“本产品”）是由腾讯云提供的一款测试产品，腾讯云享有本产品的著作权和所有权。本产品仅用于功能体验，不得用于任何商业用途。依据相关部门监管要求，严禁在使用中有任何色情、辱骂、暴恐、涉政等违法内容传播。",
  "同意": "同意",
  "取消": "取消",
  "注销": "注销",
  "关闭": "关闭",
  "开启": "开启",
  "关于腾讯云·通信": "关于腾讯云·通信",
  "消息": "消息",
  "通讯录": "通讯录",
  "个人中心": "个人中心",
  "已读回执": "已读回执",
  "消息阅读状态": "消息阅读状态",
  "关闭阅读状态": "关闭后，您收发的消息均不带消息阅读状态，您将无法看到对方是否已读，同时对方也无法看到您是否已读。",
  "开启阅读状态": "开启后，您在群聊中收发的消息均带有消息阅读状态，并且可以看到对方是否已读。与您单聊的好友若也开启了消息阅读状态，您与好友在单聊中收发的消息也将带有消息阅读状态。",
  "显示在线状态": "显示在线状态",
  "开启在线状态": "开启后，您将可以在会话列表和通讯录中看到好友在线或离线的状态提示。",
  "关闭在线状态": "关闭后，您将不可以在会话列表和通讯录中看到好友在线或离线的状态提示。",
  "消息通知": "消息通知"
}
export default Home;
