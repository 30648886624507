









































































































import { ref, onMounted, onUnmounted, watch } from '../../../../adapter-vue';
import { TUIStore, StoreName, TUITranslateService, TUIUserService, TUIGroupService } from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import Avatar from '../../../common/Avatar/index.vue';
import Drawer from '../../../common/Drawer/index.vue';
import closeIcon from '../../../../assets/icon/close-dark.svg';
import { isPC, isMobile } from '../../../../utils/env';
import { IGroupApplication, IUserProfile } from '../../../../interface';
interface IProps {
  groupID: string;
}
interface ICustomGroupApplication {
  nick: string;
  avatar: string;
  isRemoved: boolean;
  application: IGroupApplication;
}
const __sfc_main = {};
__sfc_main.props = {
  groupID: {
    key: "groupID",
    required: false,
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const drawerDomInstanceRef = ref<InstanceType<typeof Drawer>>();
  const groupApplicationCount = ref(0);
  const isGroupApplicationDrawerShow = ref(false);
  const customGroupApplicationList = ref<ICustomGroupApplication[]>([]);
  watch(isGroupApplicationDrawerShow, newVal => {
    if (newVal) {
      generateCustomGroupApplicationList().then(list => {
        customGroupApplicationList.value = list;
        groupApplicationCount.value = list.length;
      });
    }
  });
  watch(() => customGroupApplicationList.value.length, (newVal, oldVal) => {
    if (oldVal > 0 && newVal === 0) {
      isGroupApplicationDrawerShow.value = false;
    }
  });

  /**
   * Retrieves the current group application list based on the provided groupID.
   *
   * @return {Promise<IGroupApplication[]>} The list of group applications for the current group.
   */
  async function getCurrentGroupApplicationList(): Promise<IGroupApplication[]> {
    const result = await TUIGroupService.getGroupApplicationList();
    const currentGroupApplicationList: IGroupApplication[] = result.data.applicationList.filter(application => application.groupID === props.groupID);
    return currentGroupApplicationList;
  }
  function toggleGroupApplicationDrawerShow() {
    isGroupApplicationDrawerShow.value = !isGroupApplicationDrawerShow.value;
  }
  async function generateCustomGroupApplicationList(): Promise<ICustomGroupApplication[]> {
    const applicationList = await getCurrentGroupApplicationList();
    const userIDList = applicationList.map(application => application.applicationType === 0 ? application.applicant : application.userID);
    const {
      data: userProfileList
    } = (await TUIUserService.getUserProfile({
      userIDList
    }) as {
      data: IUserProfile[];
    });
    const mappingFromUserID2Profile: Record<string, IUserProfile> = {};
    userProfileList.forEach((profile: IUserProfile) => {
      mappingFromUserID2Profile[profile.userID] = profile;
    });
    const groupApplicationList: ICustomGroupApplication[] = applicationList.map(application => {
      const profile = mappingFromUserID2Profile[application.applicationType === 0 ? application.applicant : application.userID];
      return {
        nick: profile.nick || profile.userID || 'anonymous',
        avatar: profile.avatar || '',
        isRemoved: false,
        application: application
      };
    });
    return groupApplicationList;
  }
  function handleApplication(customApplication: ICustomGroupApplication, action: 'Agree' | 'Reject', index: number) {
    TUIGroupService.handleGroupApplication({
      handleAction: action,
      application: customApplication.application
    }).then(() => {
      customGroupApplicationList.value[index].isRemoved = true;
      setTimeout(() => {
        customGroupApplicationList.value.splice(index, 1);
        groupApplicationCount.value -= 1;
      }, 150);
    }).catch(() => {
      // TODO: handle error
    });
  }

  // --------------- mounted function ---------------
  onMounted(() => {
    // get current group application number on the first time entering the group
    getCurrentGroupApplicationList().then(applicationList => {
      groupApplicationCount.value = applicationList.length;
    });

    // 群未决消息列表
    TUIStore.watch(StoreName.GRP, {
      groupSystemNoticeList: onGroupSystemNoticeListUpdated
    });
  });
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.GRP, {
      groupSystemNoticeList: onGroupSystemNoticeListUpdated
    });
  });
  function onGroupSystemNoticeListUpdated() {
    // Approving or rejecting existing applications will not trigger this callback, but new applications can trigger it.
    generateCustomGroupApplicationList().then(list => {
      customGroupApplicationList.value = list;
      groupApplicationCount.value = list.length;
    });
  }
  return {
    TUITranslateService,
    closeIcon,
    isPC,
    isMobile,
    drawerDomInstanceRef,
    groupApplicationCount,
    isGroupApplicationDrawerShow,
    customGroupApplicationList,
    toggleGroupApplicationDrawerShow,
    handleApplication
  };
};
__sfc_main.components = Object.assign({
  Drawer,
  Icon,
  Avatar
}, __sfc_main.components);
export default __sfc_main;
