
















































































































import { ref, computed, onMounted, onUnmounted } from '../../../adapter-vue';
import { TUITranslateService, TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import { Dayjs } from 'dayjs';
import { globalSearchTypeList, conversationSearchTypeList, searchMessageTypeDefault } from '../search-type-list';
import { searchMessageTimeList, searchMessageTimeDefault } from '../search-time-list';
import Icon from '../../common/Icon.vue';
import DatePicker from '../../common/DatePicker/index.vue';
import downArrowIcon from '../../../assets/icon/down-icon.svg';
import closeIcon from '../../../assets/icon/input-close.svg';
import closeDarkIcon from '../../../assets/icon/close-dark.svg';
import { isPC, isUniFrameWork } from '../../../utils/env';
import { SEARCH_TYPE, ISearchMessageTime, ISearchMessageType, ISearchTimeTab, ISearchTypeTab } from '../type';
const __sfc_main = {};
__sfc_main.props = {
  popupPosition: {
    type: String,
    // 分为底部弹出和侧边弹出两种形式:"bottom"/"aside"
    default: 'bottom'
  },
  searchType: {
    type: String,
    default: 'global',
    // "global":全局搜索, "conversation":会话内搜索
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const searchTypeList = computed(() => props?.searchType === 'conversation' ? conversationSearchTypeList : globalSearchTypeList);
  const currentSearchMessageType = ref(searchMessageTypeDefault[(props?.searchType as SEARCH_TYPE)]);
  const currentSearchMessageTime = ref(searchMessageTimeDefault);
  const isTimeTabsShow = computed(() => {
    return currentSearchMessageType.value.key !== 'contact' && currentSearchMessageType.value.key !== 'group';
  });
  const datePickerRangeDisplayType = computed((): string => isPC && props.searchType === 'global' && !isUniFrameWork ? 'two' : 'one');
  const isDatePickerShow = ref<boolean>(false);
  function onCurrentSearchMessageTypeChange(typeObject: ISearchMessageType) {
    if (typeObject?.searchType === props?.searchType) {
      currentSearchMessageType.value = typeObject?.value || searchMessageTypeDefault[(props?.searchType as SEARCH_TYPE)];
    }
  }
  function onCurrentSearchMessageTimeChange(timeObject: ISearchMessageTime) {
    if (timeObject?.searchType === props?.searchType) {
      currentSearchMessageTime.value = timeObject?.value || searchMessageTimeDefault;
    }
  }
  onMounted(() => {
    TUIStore.watch(StoreName.SEARCH, {
      currentSearchMessageType: onCurrentSearchMessageTypeChange,
      currentSearchMessageTime: onCurrentSearchMessageTimeChange
    });
  });
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.SEARCH, {
      currentSearchMessageType: onCurrentSearchMessageTypeChange,
      currentSearchMessageTime: onCurrentSearchMessageTimeChange
    });
  });
  const selectSearchType = (item: ISearchTypeTab) => {
    TUIStore.update(StoreName.SEARCH, 'currentSearchMessageType', {
      value: item,
      searchType: props.searchType
    });
  };
  const selectSearchTime = (item: ISearchTimeTab) => {
    // 去除选日期情况触发 selectAllTime
    if (isDatePickerShow.value && item.key === 'all') {
      isDatePickerShow.value = false;
    } else {
      isDatePickerShow.value = false;
      TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
        value: item,
        searchType: props.searchType
      });
    }
  };
  const handleSelectAllTimeClicked = () => {
    if (currentSearchMessageTime.value?.key !== 'all') {
      TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
        value: searchMessageTimeDefault,
        searchType: props.searchType
      });
    } else {
      isDatePickerShow.value = true;
    }
  };
  const pickTimePeriod = (time: typeof Dayjs) => {
    if (currentSearchMessageTime.value?.key === 'all') {
      const {
        startDate,
        endDate
      } = time;
      const timePosition = Number((endDate?.toDate()?.getTime() / 1000).toFixed(0));
      const timePeriod = timePosition - Number((startDate?.toDate()?.getTime() / 1000).toFixed(0));
      const newSearchMessageTime = {
        key: currentSearchMessageTime.value.key,
        label: currentSearchMessageTime.value.label,
        value: {
          timePosition,
          timePeriod
        }
      };
      TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
        value: newSearchMessageTime,
        searchType: props.searchType
      });
    }
  };
  const clearTimePicker = () => {
    isDatePickerShow.value = false;
    if (currentSearchMessageTime.value?.key === 'all') {
      TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
        value: searchMessageTimeDefault,
        searchType: props.searchType
      });
    }
  };
  const closeSearchContainer = () => {
    emits('closeInConversationSearch');
  };
  return {
    TUITranslateService,
    searchMessageTimeList,
    downArrowIcon,
    closeIcon,
    closeDarkIcon,
    isPC,
    isUniFrameWork,
    props,
    searchTypeList,
    currentSearchMessageType,
    currentSearchMessageTime,
    isTimeTabsShow,
    datePickerRangeDisplayType,
    isDatePickerShow,
    selectSearchType,
    selectSearchTime,
    handleSelectAllTimeClicked,
    pickTimePeriod,
    clearTimePicker,
    closeSearchContainer
  };
};
__sfc_main.components = Object.assign({
  Icon,
  DatePicker
}, __sfc_main.components);
export default __sfc_main;
