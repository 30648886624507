var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TUI-profile",class:{ 'TUI-profile-h5': !_vm.isPC }},[(_vm.displayType !== 'setting')?_c('div',{staticClass:"TUI-profile-basic",class:{ 'TUI-profile-h5-basic': !_vm.isPC }},[_c('img',{staticClass:"TUI-profile-basic-avatar",class:{ 'TUI-profile-h5-basic-avatar': !_vm.isPC },attrs:{"src":_vm.userProfile.avatar ||
        'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'}}),_c('div',{staticClass:"TUI-profile-basic-info",class:{ 'TUI-profile-h5-basic-info': !_vm.isPC }},[_c('div',{staticClass:"TUI-profile-basic-info-nick",class:{ 'TUI-profile-h5-basic-info-nick': !_vm.isPC }},[_vm._v(" "+_vm._s(_vm.userProfile.nick || '-')+" ")]),_c('div',{staticClass:"TUI-profile-basic-info-id",class:{ 'TUI-profile-h5-basic-info-id': !_vm.isPC }},[_c('label',{staticClass:"TUI-profile-basic-info-id-label",class:{ 'TUI-profile-h5-basic-info-id-label': !_vm.isPC }},[_vm._v(_vm._s(_vm.TUITranslateService.t('Home.用户ID'))+": ")]),_c('div',{staticClass:"TUI-profile-basic-info-id-value",class:{ 'TUI-profile-basic-info-id-value': !_vm.isPC }},[_vm._v(" "+_vm._s(_vm.userProfile.userID)+" ")])])])]):_vm._e(),(_vm.displayType !== 'profile' && (!_vm.isPC || _vm.showSetting))?_c('div',{ref:"settingDomRef",staticClass:"TUI-profile-setting",class:{ 'TUI-profile-h5-setting': !_vm.isPC },on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.settingList),function(item){return _c('div',{key:item.value,staticClass:"TUI-profile-setting-item",class:{
        'TUI-profile-h5-setting-item': !_vm.isPC,
        'TUI-profile-h5-setting-item-exit': item.value === 'exit'
      }},[_c('div',{staticClass:"TUI-profile-setting-item-label",class:{ 'TUI-profile-h5-setting-item-label': !_vm.isPC },on:{"click":function($event){return _vm.handleSettingListItemOnClick(item)}}},[_c('div',{class:['label-left']},[_c('div',{class:['label-title']},[_vm._v(" "+_vm._s(item.label)+" ")]),(
              item.children && !_vm.isPC && item.childrenShowType === 'switch'
            )?_c('div',{class:['label-desc']},[_vm._v(" "+_vm._s(item.value)+" ")]):_vm._e()]),_c('div',{class:['label-right']},[(
              !_vm.isPC &&
              item.children &&
              item.selectedChild &&
              item.childrenShowType === 'bottomPopup'
            )?_c('div',{staticClass:"TUI-profile-setting-item-label-value",class:{
              'TUI-profile-h5-setting-item-label-value': !_vm.isPC
            }},[_vm._v(" "+_vm._s(item.children[item.selectedChild].label)+" ")]):_vm._e(),(item.children)?_c('Icon',{attrs:{"file":_vm.rightArrowIcon,"width":"14px","height":"14px"}}):_vm._e()],1)]),(item.children && _vm.isPC)?_c('div',{staticClass:"TUI-profile-setting-item-children",class:{ 'TUI-profile-h5-setting-item-children': !_vm.isPC }},_vm._l((item.children),function(child){return _c('div',{key:child.value,staticClass:"TUI-profile-setting-item-children-item",class:{
            'TUI-profile-h5-setting-item-children-item': !_vm.isPC
          },on:{"click":function($event){return _vm.handleSettingListItemOnClick(child)}}},[_c('div',{staticClass:"TUI-profile-setting-item-children-item-label",class:{
              'TUI-profile-h5-setting-item-children-item-label': !_vm.isPC
            }},[_vm._v(" "+_vm._s(child.label)+" ")]),(item.selectedChild === child.value)?_c('Icon',{attrs:{"file":_vm.selectedIcon,"width":"14px","height":"14px"}}):_vm._e()],1)}),0):_vm._e(),(
          item.children && !_vm.isPC && item.childrenShowType === 'bottomPopup'
        )?_c('BottomPopup',{attrs:{"show":item.showChildren},on:{"onClose":function($event){item.showChildren = false}}},_vm._l((item.children),function(child){return _c('div',{key:child.value,staticClass:"TUI-profile-setting-item-bottom-popup",class:{ 'TUI-profile-h5-setting-item-bottom-popup': !_vm.isPC },on:{"click":function($event){return _vm.handleSettingListItemOnClick(child)}}},[_vm._v(" "+_vm._s(child.label)+" ")])}),0):_vm._e()],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }