











































import { computed } from '../../adapter-vue';
import { TUIStore } from '@tencentcloud/chat-uikit-engine';
import { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import MessageBubble from '../../components/TUIChat/message-list/message-elements/message-bubble.vue';
import { isUniFrameWork } from '../../utils/env';
interface IProps {
  message: IMessageModel;
  showStyle: string;
  bubbleClassNameList?: string[];
  blinkMessageIDList?: string[];
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  },
  showStyle: {
    key: "showStyle",
    required: false,
    type: String,
    default: ''
  },
  bubbleClassNameList: {
    key: "bubbleClassNameList",
    required: false,
    type: Array,
    default: () => ([] as string[])
  },
  blinkMessageIDList: {
    key: "blinkMessageIDList",
    required: false,
    type: Array,
    default: () => ([] as string[])
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const messageModel = computed(() => TUIStore.getMessageModel(props.message?.ID));

  // 以下为messageTool等外部交互使用，无需特殊处理，勿动
  const resendMessage = (message: IMessageModel) => {
    emits('resendMessage', message);
  };
  const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
    emits('handleToggleMessageItem', e, message, isLongpress);
  };
  const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
    emits('handleH5LongPress', e, message, type);
  };
  return {
    isUniFrameWork,
    props,
    messageModel,
    resendMessage,
    handleToggleMessageItem,
    handleH5LongPress
  };
};
__sfc_main.components = Object.assign({
  MessageBubble
}, __sfc_main.components);
export default __sfc_main;
