



























import { ref, watch, withDefaults } from '../../../adapter-vue';
export interface IOverlayProps {
  visible?: boolean;
  zIndex?: number | undefined;
  useMask?: boolean | undefined;
  maskColor?: string | undefined;
  isFullScreen?: boolean | undefined;
}
const __sfc_main = {};
__sfc_main.props = {
  visible: {
    key: "visible",
    required: false,
    type: Boolean,
    default: true
  },
  zIndex: {
    key: "zIndex",
    required: false,
    type: [Number, null],
    default: 9999
  },
  useMask: {
    key: "useMask",
    required: false,
    type: [Boolean, null],
    default: true
  },
  maskColor: {
    key: "maskColor",
    required: false,
    type: [String, null],
    default: 'rgba(0, 0, 0, 0.6)'
  },
  isFullScreen: {
    key: "isFullScreen",
    required: false,
    type: [Boolean, null],
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const overlayDomRef = ref<HTMLElement>();
  const isOverlayShow = ref<boolean>(props.visible);
  watch(() => props.visible, (visible: boolean) => {
    if (visible) {
      isOverlayShow.value = true;
    } else {
      setTimeout(() => {
        isOverlayShow.value = false;
      }, 150);
    }
  }, {
    immediate: true
  });
  function onOverlayClick() {
    emits('onOverlayClick');
  }
  return Object.assign({
    props,
    overlayDomRef,
    isOverlayShow,
    onOverlayClick
  }, {
    overlayDomRef
  });
};
export default __sfc_main;
