
















import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { isPC } from '../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  enableSend: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const sendMessage = () => {
    emits('sendMessage');
  };
  return {
    TUITranslateService,
    isPC,
    props,
    sendMessage
  };
};
export default __sfc_main;
