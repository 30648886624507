




































import { computed } from '../../adapter-vue';
import { TUIStore } from '@tencentcloud/chat-uikit-engine';
import TUIChatEngine, { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { isCustomerServicePluginMessage, isCustomServiceMessageInvisible } from './message-customer/index';
import { isCallMessage, isRoomCardMessage } from './index';
import MessagePluginLayout from './message-plugin-layout.vue';
import MessageCallGroup from './message-call/message-call-group.vue';
import MessageCallC2C from './message-call/message-call-c2c.vue';
import MessageCustomerService from './message-customer/message-customer-service.vue';
// 未集成 TUIRoom 时，请引入以下路径
import MessageRoom from './message-room/message-room-default.vue';
// 集成 TUIRoom 后，请注释以上路径，放开以下路径引入
// import MessageRoom from './message-room/message-room.vue';

interface IProps {
  message: IMessageModel;
  blinkMessageIDList?: string[];
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  },
  blinkMessageIDList: {
    key: "blinkMessageIDList",
    required: false,
    type: Array,
    default: () => ([] as string[])
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const messageModel = computed(() => TUIStore.getMessageModel(props.message.ID));
  const messageSignalingInfo = computed(() => messageModel?.value?.getSignalingInfo());
  const messageCustomContent = computed(() => messageModel?.value?.getMessageContent());
  // 需要展示 ui 的判断逻辑
  const pluginMessageType = computed<{
    pluginType: string;
    showStyle: string;
  }>(() => {
    let typeObj = {
      pluginType: '',
      showStyle: ''
    };
    if (isCallMessage(messageModel.value)) {
      typeObj = {
        pluginType: 'call',
        showStyle: messageModel.value?.conversationType === TUIChatEngine.TYPES.CONV_GROUP ? 'tip' : 'bubble'
      };
    } else if (isRoomCardMessage(messageModel.value)) {
      typeObj = {
        pluginType: 'room',
        showStyle: 'bubble'
      };
    } else if (isCustomerServicePluginMessage(messageModel.value)) {
      typeObj = {
        pluginType: 'customer',
        showStyle: isCustomServiceMessageInvisible(messageModel.value) ? '' : 'bubble'
      };
    }
    return typeObj;
  });

  // 以下为messageTool等外部交互使用，无需特殊处理，勿动
  const resendMessage = (message: IMessageModel) => {
    emits('resendMessage', message);
  };
  const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
    emits('handleToggleMessageItem', e, message, isLongpress);
  };
  const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
    emits('handleH5LongPress', e, message, type);
  };
  return {
    props,
    messageSignalingInfo,
    messageCustomContent,
    pluginMessageType,
    resendMessage,
    handleToggleMessageItem,
    handleH5LongPress
  };
};
__sfc_main.components = Object.assign({
  MessagePluginLayout,
  MessageCallGroup,
  MessageCallC2C,
  MessageCustomerService,
  MessageRoom
}, __sfc_main.components);
export default __sfc_main;
