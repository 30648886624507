
































import { watchEffect, ref, onMounted, onUnmounted } from '../../../../adapter-vue';
import Icon from '../../../common/Icon.vue';
import audioIcon from '../../../../assets/icon/msg-audio.svg';
import { isPC } from '../../../../utils/env';
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  },
  messageItem: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const data = ref();
  const message = ref();
  const isAudioPlaying = ref();
  const audioRef = ref<HTMLAudioElement>();
  onMounted(() => {
    if (audioRef.value) {
      audioRef.value.addEventListener('ended', onAudioEnded);
      audioRef.value.addEventListener('pause', onAudioPaused);
    }
  });
  onUnmounted(() => {
    if (audioRef.value) {
      audioRef.value.removeEventListener('ended', onAudioEnded);
      audioRef.value.removeEventListener('pause', onAudioPaused);
    }
  });
  watchEffect(() => {
    message.value = props.messageItem;
    data.value = props.content;
  });
  function play() {
    if (message.value.hasRiskContent || !audioRef.value) {
      return;
    }
    if (!audioRef.value.paused) {
      audioRef.value.pause();
      audioRef.value.currentTime = 0;
      isAudioPlaying.value = false;
      return;
    }
    const audios = document.getElementsByTagName('audio');
    Array.from(audios).forEach(audio => {
      if (!audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
    audioRef.value.play();
    isAudioPlaying.value = true;
  }
  function onAudioEnded() {
    isAudioPlaying.value = false;
  }
  function onAudioPaused() {
    isAudioPlaying.value = false;
  }
  return {
    audioIcon,
    isPC,
    data,
    message,
    isAudioPlaying,
    audioRef,
    play
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
