<template>
  <a-menu
    style="padding-bottom: 15px"
    theme="light"
    mode="inline"
    v-if="leftMenus.length > 0"
    :selectedKeys="selectedKeys"
    :openKeys.sync="openKeys"
    @click="menuClick"
  >
    <template v-for="item in leftMenus">
      <a-menu-item
        :key="item.name"
        v-if="!item.children && item.meta.menu"
        @click="goTo({ item }, $event)"
      >
        <!-- <router-link :to="item.path"> -->
        <a-icon :type="item.meta.icon" />
        <span>{{ item.meta.name }}</span>
        <!-- </router-link> -->
      </a-menu-item>

      <a-sub-menu :key="item.name" v-if="item.children && item.meta.menu">
        <span slot="title">
          <a-icon :type="item.meta.icon" />
          <span>{{ item.meta.name }}</span>
        </span>
        <template v-for="info in item.children">
          <a-menu-item
            :key="info.name"
            v-if="info.meta.menu"
            @click="goTo({ item: info }, $event)"
          >
            {{ info.meta.name }}
          </a-menu-item>
        </template>
      </a-sub-menu>
    </template>
  </a-menu>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  data() {
    return {
      active: [],
      openKeys: [],
      leftMenus: [],
      selectedKeys: [],
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.active.push(this.$route.name);

      if (this.$route.matched.length == 2) {
        this.selectedKeys = [this.$route.matched[1].name];
      } else if (this.$route.matched.length == 3) {
        this.openKeys = [this.$route.matched[1].name];
        this.selectedKeys = [this.$route.matched[2].name];
      }
      var leftMenu = this.getLeftMenus();
      if (leftMenu != null) {
        var menu = leftMenu.filter(
          (m) => m.path == this.$route.matched[0].path
        );
        if (menu.length > 0) {
          this.leftMenus = menu[0].children.filter((m) => m.meta.menu);
        }
      }
    });
  },
  watch: {
    $route: function (to, from) {
      this.changeRoute(to);
    },
  },
  methods: {
    ...mapGetters({
      getLeftMenus: "systemMenu/getLeftMenus",
    }),

    onOpenChange(openKey) {
      var find = this.leftMenus.filter(
        (m) => m.name == openKey[openKey.length - 1]
      );
      if (find.length > 0) {
        this.openKeys = [find[0].name];
      }
    },
    menuClick(menu) {
      this.selectedKeys = [menu.keyPath[0]];
    },
    goTo(s) {
      if (s.item.meta.to) {
        window.open(
          s.item.meta.to +
            `?access_token=${this.$store.getters["currentUser/getToken"]}`
        );
        return;
      }
      this.$router.push({ path: s.item.path });
    },
    changeRoute(route) {
      this.selectedKeys = [];
      this.openKeys = [];

      if (route.matched.length == 2) {
        this.selectedKeys = [route.matched[1].name];
      } else if (route.matched.length == 3) {
        this.openKeys = [route.matched[1].name];
        this.selectedKeys = [route.matched[2].name];
      }
    },

    LeftMenuChange(e, info) {
      if (e.children != undefined) {
        this.leftMenus = [];
        this.changeRoute(this.$route);
        this.leftMenus = e.children.filter((m) => m.meta.menu);
        this.$forceUpdate();
        this.active = [];
        this.active.push(info.name);
      } else {
        this.leftMenus = [];
        this.active = [];
      }
    },
  },
};
</script>

<style>
</style>

