<template>
    <div style="height:100%">
       <router-view></router-view> 
    </div>
  </template>
  <script>
  export default {
    name: "rightMain",
    data() {
      return {
        collapsed: false
      };
    },
    mounted: function() {
      this.$nextTick(function() {
      });
    }
  };
  </script>
  <style>
  </style>
  
  