









const __sfc_main = {};
__sfc_main.props = {
  zoom: {
    type: Number,
    default: 1
  },
  rotate: {
    type: Number,
    default: 0
  },
  src: {
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {
    props
  };
};
export default __sfc_main;
