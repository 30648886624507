




const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  }
};
export default __sfc_main;
