





























































































































import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { ref, computed, withDefaults } from '../../../../../adapter-vue';
import { CHAT_MSG_CUSTOM_TYPE } from '../../../../../constant';
import { JSONToObject, isUrl } from '../../../../../utils/index';
import Icon from '../../../../common/Icon.vue';
import star from '../../../../../assets/icon/star-light.png';
import { IHighlightContent } from '../../../type';
import { ISearchResultListItem } from '../../../../../interface';
interface IProps {
  contentText: Array<IHighlightContent>;
  message: IMessageModel | ISearchResultListItem;
  messageContent: Record<string, unknown> | undefined;
}
const __sfc_main = {};
__sfc_main.props = {
  contentText: {
    key: "contentText",
    required: false,
    type: Array,
    default: () => ([] as Array<IHighlightContent>)
  },
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({} as IMessageModel)
  },
  messageContent: {
    key: "messageContent",
    required: false,
    type: [Object, null],
    default: () => ({} as Record<string, unknown>)
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const custom = ref<{
    data?: string;
    description?: string;
    extension?: string;
  }>((props?.message as IMessageModel)?.payload);
  const extensionJSON = computed(() => custom?.value?.data ? JSONToObject(custom.value.data) : custom?.value?.data);
  const businessID = computed(() => extensionJSON?.value?.businessID);
  const descriptionForShow = ref<Array<{
    text: string;
    isHighlight: boolean;
  }>>(props?.contentText);
  const defaultMessageContent = ref<string>((props?.messageContent?.custom as string) || '[自定义消息]');
  return {
    TUITranslateService,
    CHAT_MSG_CUSTOM_TYPE,
    isUrl,
    star,
    extensionJSON,
    businessID,
    descriptionForShow,
    defaultMessageContent
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
