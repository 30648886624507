import commons from "@/utils/commons.js"
const systemMenuX = {
    namespaced: true,
    state: {
        headerActive: [], // 头部选中菜单
        leftMenus: [], // 左侧菜单
        active: [], // 左侧选中菜单
        openKeys: [], // 左侧展开菜单
        rootSubmenuKeys: [], // 左侧一级菜单
        breadcrumbs: [], // 面包屑导航
        workCount: 0, // 代办数量
        messageCount: 0 // 消息数量
    },
    mutations: {
        SET_HEADER_ACTIVE(state, payload) {
            state.headerActive = payload;
        },
        SET_LEFT_MENU(state, payload) {
            state.leftMenus = payload;
            localStorage.setItem(commons.User.menus, JSON.stringify(payload))
        },
        SET_ACTIVE(state, payload) {
            state.active = payload;
        },
        SET_OPEN_KEYS(state, payload) {
            state.openKeys = payload
        },
        SET_ROOT_SUBMENU_KEYS(state, payload) {
            state.rootSubmenuKeys = payload
        },
        SET_BREADCRUMB(state, payload) {
            state.breadcrumbs = payload;
        },
        SET_WORK_COUNT(state, payload) {
            state.workCount = payload;
        },
        SET_MESSAGE_COUNT(state, payload) {
            state.messageCount = payload;
        }
    },
    getters: {
        getHeaderActive: (state) => {
            return state.headerActive;
        },
        getLeftMenus: (state) => {
            return (state.leftMenus && state.leftMenus.length > 0) ? state.leftMenus : JSON.parse(localStorage.getItem(commons.User.menus));
        },
        getActive: (state) => {
            return state.active;
        },
        getOpenKeys: (state) => {
            return state.openKeys;
        },
        getRootSubmenuKeys: (state) => {
            return state.rootSubmenuKeys;
        },
        getBreadcrumb: (state) => {
            return state.breadcrumbs;
        },
        getWorkCount: (state) => {
            return state.workCount;
        },
        getMessageCount: (state) => {
            return state.messageCount;
        }
    },
    actions: {
        setLeftMenus({ commit }, payload) {
            commit("SET_LEFT_MENU", payload);
        },
        setDefaultActive({ commit, getters }, payload) {
            let leftMenus = getters.getLeftMenus;
            // 刷新页面时传入的当前路由地址，根据路由地址找到选中的菜单，设置面包屑导航
            let activeMenu = leftMenus.find(item => item.name == payload[0]);
            if (activeMenu) {
                // 设置面包屑导航和选中菜单
                commit("SET_BREADCRUMB", { name: activeMenu.meta.name, level: 1 });
                let active = activeMenu.name;
                if (!activeMenu.meta.single && activeMenu.children) {
                    let activeSubMenu = activeMenu.children.find(item => item.name == payload[1]);
                    commit("SET_BREADCRUMB", { name: activeSubMenu.meta.name, level: 2 });
                    active = activeSubMenu.name;
                }
                commit("SET_ACTIVE", [active]);
                // 设置默认打开的菜单
                commit("SET_OPEN_KEYS", [activeMenu.name]);
            }
            // 左侧菜单所有一级菜单名称
            let keys = [];
            leftMenus.forEach(e => keys.push(e.name));
            commit("SET_ROOT_SUBMENU_KEYS", keys);
        },
        setOpenKeys({ commit }, payload) {
            commit("SET_OPEN_KEYS", payload);
        },
        setBreadcrumb({ commit }, payload) {
            commit("SET_BREADCRUMB", payload);
        },
        setHeaderActive({ commit }, payload) {
            commit("SET_HEADER_ACTIVE", payload);
        },
        setActive({ commit }, payload) {
            commit("SET_ACTIVE", payload);
        },
        setWorkCount({ commit }, payload) {
            commit("SET_WORK_COUNT", payload);
        },
        setMessageCount({ commit }, payload) {
            commit("SET_MESSAGE_COUNT", payload);
        }
    }
}

export default systemMenuX