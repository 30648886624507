import Vue from 'vue'
import Router from 'vue-router'
import commons from "@/utils/commons.js"
import config from "@/config"
Vue.use(Router)

import main from '@/views/Main'
import rightMain from "@/components/RightMain"
export const routes = [
    {
        path: "/login",
        name: "login",
        hidden: true,
        meta: {
            name: "登录",
        },
        component: resolve => require(['@/views/Login'], resolve),
    }, {
        path: "/chat",
        name: "chat",
        hidden: true,
        meta: {
            name: "chat",
        },
        component: resolve => require(['@/views/Chat'], resolve),
    },
    {
        path: "/wpsedit",
        name: "wpsedit",
        hidden: true,
        meta: {
            name: "wps",
        },
        component: resolve => require(['@/views/business/components/wpsedit'], resolve),
    },
    {
        path: "/wpsRead",
        name: "wpsRead",
        hidden: true,
        meta: {
            name: "wps",
        },
        component: resolve => require(['@/views/business/components/wpsRead'], resolve),
    },
    {
        path: "/",
        name: "home",
        redirect: "/home",
        hidden: false,
        component: main,
        meta: {
            single: true,
            requireAuth: false,
            name: "首页",
            icon: "home",
            roles: [],
            menu: true,
        },
        children: [
            {
                path: "/home",
                name: "home",
                hidden: true,
                meta: {
                    requireAuth: false,
                    roles: [],
                    name: "首页",
                    menu: true
                },
                component: resolve => require(['@/views/Home'], resolve),
            },
            {
                path: "/home/newslist",
                name: "newslist",
                hidden: true,
                meta: {
                    requireAuth: false,
                    roles: [],
                    name: "新闻列表",
                    menu: false
                },
                component: resolve => require(['@/views/work/newslist'], resolve),
            }
        ]
    },

    {
        path: "/work",
        name: "work",
        meta: {
            requireAuth: false,
            single: true,
            name: "待办事项",
            icon: "sound",
            roles: [],
            menu: true,
        },
        component: main,
        children: [
            {
                path: "/work/home",
                name: "workhome",
                hidden: true,
                meta: {
                    requireAuth: false,
                    name: "首页",
                    icon: "layout",
                    menu: true,
                },
                component: resolve => require(['@/views/work/home.vue'], resolve),
            }

        ]
    },
    {
        path: "/business",
        name: "business",
        meta: {
            requireAuth: false,
            single: false,
            name: "网络办公",
            icon: "sound",
            roles: [],
            menu: true,
        },
        component: main,
        children: [
            {
                path: "/business/documentread",
                name: "documentread",
                meta: {
                    name: "来文阅办",
                    requireAuth: true,
                    icon: "read",
                    menu: true
                },
                component: resolve => require(['@/views/business/document/documentread'], resolve),
            },
            {
                path: "/business/documentdispatch",
                name: "documentdispatch",
                meta: {
                    name: "发文办理",
                    requireAuth: true,
                    icon: "audit",
                    menu: true
                },
                component: resolve => require(['@/views/business/dispatch/documentdispatch'], resolve),
            },
            {
                path: "/business/newdocument",
                name: "newdocument",
                meta: {
                    name: "新建发文",
                    requireAuth: false,
                    icon: "usergroup-add",
                    menu: false
                },
                component: resolve => require(['@/views/business/dispatch/newdocument'], resolve),
            },
            {
                path: "/business/documentprint",
                name: "documentprint",
                meta: {
                    name: "文印服务",
                    requireAuth: true,
                    icon: "printer",
                    menu: true
                },
                component: resolve => require(['@/views/business/document/documentprint'], resolve),
            },
            {
                path: "/business/documentprintShow",
                name: "documentprintShow",
                meta: {
                    name: "文印汇总",
                    requireAuth: true,
                    icon: "printer",
                    menu: true
                },
                component: resolve => require(['@/views/business/document/documentprintShow'], resolve),
            },
            {
                path: "/business/postdata",
                name: "postdata",
                meta: {
                    name: "邮寄服务",
                    requireAuth: true,
                    icon: "mail",
                    menu: true
                },
                component: resolve => require(['@/views/business/personal/postdata'], resolve),
            },
            {
                path: "/business/postdataShow",
                name: "postdataShow",
                meta: {
                    name: "邮寄汇总",
                    requireAuth: true,
                    icon: "mail",
                    menu: true
                },
                component: resolve => require(['@/views/business/personal/postdataShow'], resolve),
            },
            {
                path: "/business/officialoutinfo",
                name: "officialoutinfo",
                meta: {
                    name: "出差申请",
                    requireAuth: false,
                    icon: "solution",
                    menu: false
                },
                component: resolve => require(['@/views/business/personal/officialoutinfo.vue'], resolve),
            },
            {
                path: "/business/officialout",
                name: "officialout",
                meta: {
                    name: "出差申请",
                    requireAuth: true,
                    icon: "usergroup-add",
                    menu: true
                },
                component: resolve => require(['@/views/business/personal/officialout.vue'], resolve),
            },
            {
                path: "/business/businessloutinfo",
                name: "businessoutinfo",
                meta: {
                    name: "业务外出",
                    requireAuth: true,
                    icon: "solution",
                    menu: false
                },
                component: resolve => require(['@/views/business/personal/businessoutinfo.vue'], resolve),
            },
            {
                path: "/business/businessout",
                name: "businessout",
                meta: {
                    name: "业务外出",
                    requireAuth: false,
                    icon: "usergroup-add",
                    menu: true
                },
                component: resolve => require(['@/views/business/personal/businessout.vue'], resolve),
            },

            {
                path: "/business/leaveapplicationinfo",
                name: "leaveapplicationinfo",
                meta: {
                    name: "请假申请",
                    requireAuth: false,
                    icon: "file-done",
                    menu: false
                },
                component: resolve => require(['@/views/business/personal/leaveapplicationinfo.vue'], resolve),
            },
            {
                path: "/business/leaveapplication",
                name: "leaveapplication",
                meta: {
                    name: "请假申请",
                    requireAuth: true,
                    icon: "usergroup-add",
                    menu: true
                },
                component: resolve => require(['@/views/business/personal/leaveapplication.vue'], resolve),
            },
            {
                path: "/business/bookcarinfo",
                name: "bookcarinfo",
                meta: {
                    name: "公务租车",
                    requireAuth: false,
                    icon: "solution",
                    menu: false
                },
                component: resolve => require(['@/views/business/finance/bookcarinfo.vue'], resolve),
            },
            {
                path: "/business/bookcarlist",
                name: "bookcarlist",
                meta: {
                    name: "公务租车",
                    requireAuth: true,
                    icon: "solution",
                    menu: true
                },
                component: resolve => require(['@/views/business/finance/bookcarlist.vue'], resolve),
            },
            {
                path: "/business/asset",
                name: "asset",
                meta: {
                    name: "资产服务",
                    requireAuth: false,
                    icon: "tool",
                    menu: true
                },
                component: rightMain,
                children: [
                    {
                        path: "/business/assetpurchaselist",
                        name: "assetpurchaselist",
                        meta: {
                            name: "资产请购",
                            requireAuth: true,
                            icon: "shopping",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/asset/assetpurchaselist.vue'], resolve),
                    },
                    // {
                    //     path: "/business/assetapplylist",
                    //     name: "assetapplylist",
                    //     meta: {
                    //         name: "资产申领",
                    //         requireAuth: true,
                    //         icon: "select",
                    //         menu:true
                    //     },
                    //     component: resolve => require(['@/views/business/asset/assetapplylist.vue'], resolve),
                    // },
                    {
                        path: "/business/assetrepairlist",
                        name: "assetrepairlist",
                        meta: {
                            name: "资产报修",
                            requireAuth: true,
                            icon: "tool",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/asset/assetrepairlist.vue'], resolve),
                    },
                    {
                        path: "/assetmanage",
                        name: "assetmanage",
                        meta: {
                            requireAuth: true,
                            single: false,
                            name: "资产管理",
                            icon: "sound",
                            roles: [],
                            menu: true,
                            to: "http://192.168.0.201:8888/DWB/"
                        },
                        component: main
                    },
                ],
            },
            {
                path: "/business/finance",
                name: "finance",
                meta: {
                    name: "预算编报",
                    requireAuth: false,
                    icon: "calculator",
                    menu: true
                },
                component: rightMain,
                children: [
                    {
                        path: "/business/annualbudget",
                        name: "annualbudget",
                        meta: {
                            name: "年度预算",
                            requireAuth: false,
                            icon: "calculator",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/annualbudget.vue'], resolve),
                    },
                    {
                        path: "/business/annualbudgetlist",
                        name: "annualbudgetlist",
                        meta: {
                            name: "年度预算",
                            requireAuth: true,
                            icon: "usergroup-add",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/annualbudgetlist.vue'], resolve),
                    },
                    {
                        path: "/business/publicbudget",
                        name: "publicbudget",
                        meta: {
                            name: "公用经费预算",
                            requireAuth: false,
                            icon: "usergroup-add",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/publicbudget.vue'], resolve),
                    },
                    {
                        path: "/business/publicbudgetlist",
                        name: "publicbudgetlist",
                        meta: {
                            name: "公用经费预算",
                            requireAuth: true,
                            icon: "usergroup-add",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/publicbudgetlist.vue'], resolve),
                    },
                    {
                        path: "/business/specialfund",
                        name: "specialfund",
                        meta: {
                            name: "专项资金预算",
                            requireAuth: false,
                            icon: "property-safety",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/specialfund.vue'], resolve),
                    },
                    {
                        path: "/business/specialfundlist",
                        name: "specialfundlist",
                        meta: {
                            name: "专项资金预算",
                            requireAuth: true,
                            icon: "property-safety",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/specialfundlist.vue'], resolve),
                    },
                    {
                        path: "/business/escrowfundbudget",
                        name: "escrowfundbudget",
                        meta: {
                            name: "代管项目资金预算",
                            requireAuth: false,
                            icon: "property-safety",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/escrowfundbudget.vue'], resolve),
                    },
                    {
                        path: "/business/escrowfundbudgetlist",
                        name: "escrowfundbudgetlist",
                        meta: {
                            name: "代管项目资金预算",
                            requireAuth: true,
                            icon: "property-safety",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/escrowfundbudgetlist.vue'], resolve),
                    },
                ]
            },
            {
                path: "/business/expense",
                name: "expense",
                meta: {
                    name: "收入、支出与报销",
                    requireAuth: false,
                    icon: "profile",
                    menu: true
                },
                component: rightMain,
                children: [

                    // {
                    //     path: "/business/publicbudget2",
                    //     name: "publicbudget2",
                    //     meta: {
                    //         name: "公用经费支出申请",
                    //         requireAuth: true,
                    //         icon: "usergroup-add",
                    //         menu: true
                    //     },
                    //     component: resolve => require(['@/views/business/finance/publicbudget.vue'], resolve),
                    // },
                    {
                        path: "/business/publicbill",
                        name: "publicbill",
                        meta: {
                            name: "公用票据整理单",
                            requireAuth: false,
                            icon: "transaction",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/publicbill.vue'], resolve),
                    },
                    {
                        path: "/business/publicbilllist",
                        name: "publicbilllist",
                        meta: {
                            name: "公用票据整理单",
                            requireAuth: true,
                            icon: "transaction",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/publicbilllist.vue'], resolve),
                    },
                    {
                        path: "/business/specialexpenditure",
                        name: "specialexpenditure",
                        meta: {
                            name: "专项资金支出申请",
                            requireAuth: false,
                            icon: "transaction",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/specialexpenditure.vue'], resolve),
                    },
                    {
                        path: "/business/specialexpenditurelist",
                        name: "specialexpenditurelist",
                        meta: {
                            name: "专项资金支出申请表",
                            requireAuth: true,
                            icon: "transaction",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/specialexpenditurelist.vue'], resolve),
                    },
                    {
                        path: "/business/officialoutsign",
                        name: "officialoutsign",
                        meta: {
                            name: "出差审批单",
                            requireAuth: true,
                            icon: "schedule",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/officialapprovallist.vue'], resolve),
                    },
                    {
                        path: "/business/officialapprovalinfo",
                        name: "officialapprovalinfo",
                        meta: {
                            name: "出差审批单",
                            requireAuth: false,
                            icon: "usergroup-add",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/officialapprovalinfo.vue'], resolve),
                    },
                    {
                        path: "/business/travelexpenselist",
                        name: "travelexpenselist",
                        meta: {
                            name: "差旅费报销凭证",
                            requireAuth: true,
                            icon: "usergroup-add",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/travelexpenselist.vue'], resolve),
                    },
                    {
                        path: "/business/travelexpense",
                        name: "travelexpense",
                        meta: {
                            name: "差旅费报销凭证",
                            requireAuth: false,
                            icon: "schedule",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/travelexpense.vue'], resolve),
                    },
                    {
                        path: "/business/loanbill",
                        name: "loanbill",
                        meta: {
                            name: "借款单",
                            requireAuth: false,
                            icon: "profile",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/loanbill.vue'], resolve),
                    },
                    {
                        path: "/business/loanbilllist",
                        name: "loanbilllist",
                        meta: {
                            name: "借款单",
                            requireAuth: true,
                            icon: "usergroup-add",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/loanbilllist.vue'], resolve),
                    },
                    {
                        path: "/business/incomebill",
                        name: "incomebill",
                        meta: {
                            name: "收入票据整理单",
                            requireAuth: false,
                            icon: "profile",
                            menu: false
                        },
                        component: resolve => require(['@/views/business/finance/incomebill.vue'], resolve),
                    },
                    {
                        path: "/business/incomebilllist",
                        name: "incomebilllist",
                        meta: {
                            name: "收入票据整理单",
                            requireAuth: true,
                            icon: "usergroup-add",
                            menu: true
                        },
                        component: resolve => require(['@/views/business/finance/incomebilllist.vue'], resolve),
                    },
                ]
            },
        ]
    },
    {
        path: "/personal",
        name: "personal",
        meta: {
            requireAuth: false,
            single: false,
            name: "个人服务",
            icon: "sound",
            roles: [],
            menu: true,
        },
        component: main,
        children: [
            {
                path: "/personal/salary",
                name: "salary",
                meta: {
                    name: "工资查询",
                    requireAuth: true,
                    icon: "wallet",
                    menu: true
                },
                component: resolve => require(['@/views/personal/salary'], resolve),
            },
            {
                path: "/personal/documentsearch",
                name: "documentsearch",
                meta: {
                    name: "文献查询",
                    requireAuth: true,
                    icon: "search",
                    menu: true
                },
                component: resolve => require(['@/views/personal/documentsearch'], resolve),
            },
            {
                path: "/personal/cookbook",
                name: "cookbook",
                meta: {
                    name: "每周菜谱",
                    requireAuth: true,
                    icon: "schedule",
                    menu: true
                },
                component: resolve => require(['@/views/personal/cookbook'], resolve),
            }
        ]
    },
    // {
    //     path: "/chat",
    //     name: "chat",
    //     meta: {
    //         requireAuth: false,
    //         single: false,
    //         name: "即时通讯",
    //         icon: "sound",
    //         roles: [],
    //         menu: true,
    //         to: window.location.origin+'/#/chat'
    //     },
    //     // component: main,
    // },
    {
        path: "/cms",
        name: "cms",
        meta: {
            requireAuth: false,
            single: false,
            name: "信息发布",
            icon: "sound",
            roles: [],
            menu: true,
        },
        component: main,
        children: [
            {
                path: "/cms/newsArticleList",
                name: "newsArticleList",
                meta: {
                    requireAuth: true,
                    name: "内容列表",
                    icon: "container",
                    menu: true,
                    roles: [],
                },
                component: resolve => require(['@/views/cms/NewsArticleList.vue'], resolve),
            },
            {
                path: "/cms/categoryList",
                name: "categoryList",
                meta: {
                    requireAuth: true,
                    name: "内容分类",
                    icon: "profile",
                    menu: true,
                    roles: [],
                },
                component: resolve => require(['@/views/cms/CategoryList.vue'], resolve),
            },
            {
                path: "/cms/staffArticleList",
                name: "staffArticleList",
                meta: {
                    requireAuth: false,
                    name: "职工园地",
                    icon: "profile",
                    menu: true,
                    roles: [],
                },
                component: resolve => require(['@/views/cms/StaffArticleList.vue'], resolve),
            }

        ]
    },
    {
        path: "/system",
        name: "system",
        meta: {
            requireAuth: true,
            single: false,
            name: "配置管理",
            icon: "sound",
            roles: [],
            menu: true,
        },
        component: main,
        children: [
            {
                path: "/system/DictionaryList",
                name: "DictionaryList",
                meta: {
                    name: "数据字典",
                    requireAuth: true,
                    icon: "container",
                    menu: true
                },
                component: resolve => require(['@/views/dictionary/DictionaryList'], resolve),
            },
            {
                path: "/system/workflow",
                name: "workflow",
                meta: {
                    name: "流程定义",
                    requireAuth: true,
                    icon: "branches",
                    menu: true
                },
                component: resolve => require(['@/views/workflow/definition'], resolve),
            },
            {
                path: "/system/documentsearchsetting",
                name: "documentsearchsetting",
                meta: {
                    name: "文献查询账户",
                    requireAuth: true,
                    icon: "container",
                    menu: true
                },
                component: resolve => require(['@/views/personal/documentsearchsetting'], resolve),
            },
        ]
    },
    {
        path: "/office",
        name: "office",
        meta: {
            requireAuth: false,
            single: false,
            name: "专业办公",
            icon: "sound",
            roles: [],
            menu: true,
            dropdown: true
        },
        children: [
            {
                path: "/office/edit",
                name: "edit",
                meta: {
                    name: "编辑平台",
                    requireAuth: false,
                    icon: "container",
                    menu: true,
                    to: "http://bjb.hbsjykxyjy.cn/#/editLogin"
                },
            },
            {
                path: "/office/asset",
                name: "asset",
                meta: {
                    name: "资产管理平台",
                    requireAuth: false,
                    icon: "branches",
                    menu: true,
                    to: "http://192.168.0.201:8888/DWB/"
                },
            },
            // {
            //     path: "/office/archives",
            //     name: "archives",
            //     meta: {
            //         name: "档案管理平台",
            //         requireAuth: false,
            //         icon: "container",
            //         menu: true,
            //         to: "http://www.baidu.com"
            //     },
            // },
        ]
    },
    {
        path: '*',
        name: "notFound",
        hidden: true,
        meta: {
            name: "404",
        },
        component: resolve => require(['@/components/NotFound'], resolve),
    }

]

const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes
});

const router = createRouter();

router.beforeEach(async (to, from, next) => {
    from.meta.savedPosition = document.body.scrollTop || document.documentElement.scrollTop;
    document.title = `${config.appName.replace("<br/>", "")}-${to.meta.name}`;
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (localStorage.getItem(commons.User.Authentication)) {
            next()
        } else {
            next({ path: "/login", query: { redirect: to.fullPath } })
        }
    } else {
        next();
    }
})

export default router;
