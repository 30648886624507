































































































































import { ref, watchEffect, onMounted, onUnmounted, withDefaults } from '../../../adapter-vue';
import { IMessageModel, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal, getPlatform } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import iconClose from '../../../assets/icon/icon-close.svg';
import iconArrowLeft from '../../../assets/icon/icon-arrow-left.svg';
import iconZoomIn from '../../../assets/icon/zoom-in.svg';
import iconZoomOut from '../../../assets/icon/zoom-out.svg';
import iconRotateLeft from '../../../assets/icon/rotate-left.svg';
import iconRotateRight from '../../../assets/icon/rotate-right.svg';
import iconDownload from '../../../assets/icon/download.svg';
import ImageItem from './image-item.vue';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import { isPC, isMobile, isUniFrameWork } from '../../../utils/env';
interface touchesPosition {
  pageX1?: number;
  pageY1?: number;
  pageX2?: number;
  pageY2?: number;
}
const __sfc_main = {};
__sfc_main.props = {
  imageList: {
    key: "imageList",
    required: false,
    type: Array,
    default: () => ([] as Array<IMessageModel>)
  },
  currentImage: {
    key: "currentImage",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const imageFormatMap = new Map([[1, 'jpg'], [2, 'gif'], [3, 'png'], [4, 'bmp']]);
  const emit = __ctx.emit;
  const zoom = ref(1);
  const rotate = ref(0);
  const minZoom = ref(0.1);
  const currentImageIndex = ref(0);
  const image = ref();
  const ulRef = ref();
  // touch
  let startX = 0;
  const touchStore = ({} as touchesPosition);
  let moveFlag = false;
  let twoTouchesFlag = false;
  let timer: number | null = null;
  watchEffect(() => {
    currentImageIndex.value = props.imageList.findIndex((message: any) => {
      return message.ID === props?.currentImage?.ID;
    });
  });
  const isNumber = (value: any) => {
    return typeof value === 'number' && isFinite(value);
  };
  const handleTouchStart = (e: any) => {
    e.preventDefault();
    moveInit(e);
    twoTouchesInit(e);
  };
  const handleTouchMove = (e: any) => {
    e.preventDefault();
    moveFlag = true;
    if (e.touches && e.touches.length === 2) {
      twoTouchesFlag = true;
      handleTwoTouches(e);
    }
  };
  const handleTouchEnd = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let moveEndX = 0;
    let X = 0;
    if (twoTouchesFlag) {
      if (!timer) {
        twoTouchesFlag = false;
        delete touchStore.pageX2;
        delete touchStore.pageY2;
        timer = setTimeout(() => {
          timer = null;
        }, 200);
      }
      return;
    }
    // H5 touch move to left to go to prev image
    // H5 touch move to right to go to next image
    if (timer === null) {
      switch (moveFlag) {
        // touch event
        case true:
          moveEndX = e?.changedTouches[0]?.pageX;
          X = moveEndX - startX;
          if (X > 100) {
            goPrev();
          } else if (X < -100) {
            goNext();
          }
          break;
        // click event
        case false:
          close();
          break;
      }
      timer = setTimeout(() => {
        timer = null;
      }, 200);
    }
  };
  const handleTouchCancel = () => {
    twoTouchesFlag = false;
    delete touchStore.pageX1;
    delete touchStore.pageY1;
  };
  const handleWheel = (e: any) => {
    e.preventDefault();
    if (Math.abs(e.deltaX) !== 0 && Math.abs(e.deltaY) !== 0) return;
    let scale = zoom.value;
    scale += e.deltaY * (e.ctrlKey ? -0.01 : 0.002);
    scale = Math.min(Math.max(0.125, scale), 4);
    zoom.value = scale;
  };
  const moveInit = (e: any) => {
    startX = e?.changedTouches[0]?.pageX;
    moveFlag = false;
  };
  const twoTouchesInit = (e: any) => {
    const touch1 = e?.touches[0];
    const touch2 = e?.touches[1];
    touchStore.pageX1 = touch1?.pageX;
    touchStore.pageY1 = touch1?.pageY;
    if (touch2) {
      touchStore.pageX2 = touch2?.pageX;
      touchStore.pageY2 = touch2?.pageY;
    }
  };
  const handleTwoTouches = (e: any) => {
    const touch1 = e?.touches[0];
    const touch2 = e?.touches[1];
    if (touch2) {
      if (!isNumber(touchStore.pageX2)) {
        touchStore.pageX2 = touch2.pageX;
      }
      if (!isNumber(touchStore.pageY2)) {
        touchStore.pageY2 = touch2.pageY;
      }
    }
    const getDistance = (startX: number, startY: number, stopX: number, stopY: number) => {
      return Math.hypot(stopX - startX, stopY - startY);
    };
    if (!isNumber(touchStore.pageX1) || !isNumber(touchStore.pageY1) || !isNumber(touchStore.pageX2) || !isNumber(touchStore.pageY2)) {
      return;
    }
    const touchZoom = getDistance(touch1.pageX, touch1.pageY, touch2.pageX, touch2.pageY) / getDistance((touchStore.pageX1 as number), (touchStore.pageY1 as number), (touchStore.pageX2 as number), (touchStore.pageY2 as number));
    zoom.value = Math.min(Math.max(0.5, zoom.value * touchZoom), 4);
  };
  onMounted(() => {
    // web: close on esc keydown
    document?.addEventListener && document?.addEventListener('keydown', handleEsc);
  });
  const handleEsc = (e: any) => {
    e.preventDefault();
    if (e?.keyCode === 27) {
      close();
    }
  };
  const zoomIn = () => {
    zoom.value += 0.1;
  };
  const zoomOut = () => {
    zoom.value = zoom.value - 0.1 > minZoom.value ? zoom.value - 0.1 : minZoom.value;
  };
  const close = () => {
    emit('close');
  };
  const rotateLeft = () => {
    rotate.value -= 90;
  };
  const rotateRight = () => {
    rotate.value += 90;
  };
  const goNext = () => {
    currentImageIndex.value < props.imageList.length - 1 && currentImageIndex.value++;
    initStyle();
  };
  const goPrev = () => {
    currentImageIndex.value > 0 && currentImageIndex.value--;
    initStyle();
  };
  const initStyle = () => {
    zoom.value = 1;
    rotate.value = 0;
  };
  const getImageUrl = (message: IMessageModel) => {
    if (isPC) {
      return message?.payload?.imageInfoArray[0]?.url;
    } else {
      return message?.payload?.imageInfoArray[2]?.url;
    }
  };
  const save = () => {
    const imageMessage = (props.imageList[currentImageIndex.value] as IMessageModel);
    const imageSrc = imageMessage?.payload?.imageInfoArray[0]?.url;
    if (!imageSrc) {
      Toast({
        message: TUITranslateService.t('component.图片 url 不存在'),
        type: TOAST_TYPE.ERROR
      });
      return;
    }
    switch (getPlatform()) {
      case 'wechat':
        // 获取用户的当前设置，获取相册权限
        TUIGlobal.getSetting({
          success: (res: any) => {
            // 如果没有相册权限
            if (!res?.authSetting['scope.writePhotosAlbum']) {
              // 提前向用户发起授权请求
              TUIGlobal.authorize({
                scope: 'scope.writePhotosAlbum',
                success() {
                  downloadImgInUni(imageSrc);
                },
                fail() {
                  TUIGlobal.showModal({
                    title: '您已拒绝获取相册权限',
                    content: '是否进入权限管理，调整授权？',
                    success: (res: any) => {
                      if (res.confirm) {
                        // 调起客户端小程序设置界面，返回用户设置的操作结果。（重新让用户授权）
                        TUIGlobal.openSetting({
                          success: (res: any) => {
                            console.log(res.authSetting);
                          }
                        });
                      } else if (res.cancel) {
                        return Toast({
                          message: TUITranslateService.t('component.已取消'),
                          type: TOAST_TYPE.ERROR
                        });
                      }
                    }
                  });
                }
              });
            } else {
              // 如果已有相册权限，直接进行保存到相册
              downloadImgInUni(imageSrc);
            }
          },
          fail: () => {
            Toast({
              message: TUITranslateService.t('component.获取权限失败'),
              type: TOAST_TYPE.ERROR
            });
          }
        });
        break;
      case 'app':
        downloadImgInUni(imageSrc);
        break;
      default:
        downloadImgInWeb(imageSrc);
        break;
    }
  };
  const downloadImgInUni = (src: string) => {
    // 下载图片文件
    TUIGlobal.showLoading({
      title: '大图提取中'
    });
    TUIGlobal.downloadFile({
      url: src,
      success: function (res: any) {
        TUIGlobal.hideLoading();
        TUIGlobal.saveImageToPhotosAlbum({
          filePath: res.tempFilePath,
          success: () => {
            Toast({
              message: TUITranslateService.t('component.已保存至相册'),
              type: TOAST_TYPE.SUCCESS
            });
          }
        });
      },
      fail: function () {
        TUIGlobal.hideLoading();
        Toast({
          message: TUITranslateService.t('component.图片下载失败'),
          type: TOAST_TYPE.ERROR
        });
      }
    });
  };
  const downloadImgInWeb = (src: string) => {
    const option: any = {
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    const imageMessage = (props.imageList[currentImageIndex.value] as IMessageModel);
    const imageFormat: number = imageMessage?.payload?.imageFormat;
    if (!imageFormatMap.has(imageFormat)) {
      Toast({
        message: TUITranslateService.t('component.暂不支持下载此类型图片'),
        type: TOAST_TYPE.ERROR
      });
      return;
    }
    // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
    // If the browser supports fetch, use blob to download, so as to avoid the browser clicking the a tag and jumping to the preview of the new page
    if ((window as any).fetch) {
      fetch(src, option).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = url + '.' + imageFormatMap.get(imageFormat);
        a.click();
      });
    } else {
      const a = document.createElement('a');
      a.href = src;
      a.target = '_blank';
      a.download = src + '.' + imageFormatMap.get(imageFormat);
      a.click();
    }
  };
  onUnmounted(() => {
    document?.removeEventListener && document?.removeEventListener('keydown', handleEsc);
  });
  return {
    iconClose,
    iconArrowLeft,
    iconZoomIn,
    iconZoomOut,
    iconRotateLeft,
    iconRotateRight,
    iconDownload,
    isPC,
    isMobile,
    isUniFrameWork,
    zoom,
    rotate,
    currentImageIndex,
    image,
    ulRef,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleTouchCancel,
    handleWheel,
    zoomIn,
    zoomOut,
    close,
    rotateLeft,
    rotateRight,
    goNext,
    goPrev,
    getImageUrl,
    save
  };
};
__sfc_main.components = Object.assign({
  ImageItem,
  Icon
}, __sfc_main.components);
export default __sfc_main;
