const Login = {
  "登录": "登录",
  "登录当前账号": "登录当前账号",
  "切换其他账号": "切换其他账号",
  "我已阅读并同意": "我已阅读并同意",
  "和": "和",
  "隐私条例": "隐私条例",
  "用户协议": "用户协议",
  "免费体验": "免费体验",
  "体验更多Demo": "体验更多Demo",
  "访问官网": "访问官网",
  "超10亿用户的信赖": "超10亿用户的信赖",
  "一分钟": "一分钟",
  "改2行代码，1分钟跑通demo": "改2行代码，1分钟跑通demo",
  "每月服务客户数超过10000家": "每月服务客户数超过10000家",
  "消息收发成功率": "消息收发成功率，服务可靠性高于99.99%",
  "10亿": "10亿",
  "每月活跃用户数超过10亿": "每月活跃用户数超过10亿",
  "请输入手机号": "请输入手机号",
  "请输入userID": "请输入userID",
  "请输入正确的手机号": "请输入正确的手机号",
  "请先勾选用户协议": "请先勾选用户协议",
  "请输入验证码": "请输入验证码",
  "获取验证码": "获取验证码",
  "登录失败": "登录失败",
  "验证码发送成功": "验证码发送成功",
  "发送验证码失败": "验证码发送失败",
  "Android": "Android",
  "iOS": "iOS",
  "小程序": "小程序",
  "Windows": "Windows",
  "Mac OS": "Mac OS",
  "扫描二维码下载": "扫描二维码下载",
  "微信扫码进入": "微信扫码进入",
  "点击直接下载": "点击直接下载",
  "更多客户端体验": "更多客户端体验",
  "微信扫一扫，免费体验腾讯云 IM 小程序": "微信扫一扫，免费体验腾讯云 IM 小程序",
  "或者截图至相册，切换微信扫一扫识别体验": "或者截图至相册，切换微信扫一扫识别体验"
}
export default Login;