













import { watchEffect, ref, nextTick } from '../../../../adapter-vue';
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  },
  isPC: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const data = ref();
  const skeleton: any = ref();
  watchEffect(() => {
    data.value = props.content;
    if (!data.value) return;
    nextTick(() => {
      // todo 大小显示
    });
  });
  return {
    data,
    skeleton
  };
};
export default __sfc_main;
