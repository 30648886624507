
























import { withDefaults, computed } from '../../adapter-vue';
import { isApp, isPC } from '../../utils/env';
interface IProps {
  file: string;
  size?: string;
  width?: string;
  height?: string;
  hotAreaSize?: number | string;
}
interface IEmits {
  (key: 'onClick', e: Event): void;
}
const __sfc_main = {};
__sfc_main.props = {
  file: {
    key: "file",
    required: false,
    type: String,
    default: ''
  },
  size: {
    key: "size",
    required: false,
    type: String
  },
  width: {
    key: "width",
    required: false,
    type: String,
    default: '20px'
  },
  height: {
    key: "height",
    required: false,
    type: String,
    default: '20px'
  },
  hotAreaSize: {
    key: "hotAreaSize",
    required: false,
    type: [Number, String]
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const iconHotAreaSize = computed<undefined | string>(() => {
    if (!props.hotAreaSize) {
      return undefined;
    }
    if (isNaN(Number(props.hotAreaSize))) {
      return String(props.hotAreaSize);
    }
    return `${props.hotAreaSize}px`;
  });
  const iconWidth = computed(() => {
    return props.size ? props.size : props.width;
  });
  const iconHeight = computed(() => {
    return props.size ? props.size : props.height;
  });
  const handleImgClick = (e: Event) => {
    emits('onClick', e);
  };
  return {
    isApp,
    isPC,
    props,
    iconHotAreaSize,
    iconWidth,
    iconHeight,
    handleImgClick
  };
};
export default __sfc_main;
