var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.translationVisible)?_c('div',{ref:"translationWrapperRef",class:{
    'message-translation': true,
    'reverse': _vm.props.message.flow === 'out',
    'error': _vm.translationError,
  }},[_c('div',{staticClass:"message-translation-container",style:({
      height: _vm.calculateHeight > 0 ? (_vm.calculateHeight + "px") : 'auto',
      width: _vm.calculateWidth > 0 ? (_vm.calculateWidth + "px") : 'auto',
    })},[(_vm.translationFinished)?_c('div',{ref:"translationContentRef",class:{
        'translation-content': true,
        'occur': _vm.calculateHeight > 0,
      }},[_vm._v(" "+_vm._s(_vm.translationText)+" ")]):_vm._e(),_c('div',{ref:"translationLoadingRef",class:{
        'loading': true,
        'loading-end': _vm.translationFinished
      }},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t('TUIChat.翻译中'))+"... ")])]),_c('div',{staticClass:"copyright"},[_c('Icon',{attrs:{"file":_vm.checkIcon,"size":"13px"}}),_c('div',{staticClass:"copyright-text"},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t('TUIChat.由IM提供翻译支持'))+" ")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }