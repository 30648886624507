















import { TUIStore, StoreName, IConversationModel } from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../../../adapter-vue';
import { isH5 } from '../../../../utils/env';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import EmojiPickerDialog from './emoji-picker-dialog.vue';
import faceIcon from '../../../../assets/icon/face.png';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const currentConversation = ref();
  const container = ref();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  const onIconClick = (dialogRef: any) => {
    if (!isH5) {
      emits('toggleComponent');
      return;
    }
    emits('dialogShowInH5', dialogRef.value);
  };
  const onDialogClose = (dialogRef: any) => {
    if (!isH5) {
      return;
    }
    emits('dialogCloseInH5', dialogRef.value);
  };
  const insertEmoji = emojiObj => {
    emits('insertEmoji', emojiObj);
  };
  const sendMessage = () => {
    emits('sendMessage');
  };
  const onClose = () => {
    container?.value?.toggleDialogDisplay(false);
  };
  return {
    faceIcon,
    container,
    onIconClick,
    onDialogClose,
    insertEmoji,
    sendMessage,
    onClose
  };
};
__sfc_main.components = Object.assign({
  ToolbarItemContainer,
  EmojiPickerDialog
}, __sfc_main.components);
export default __sfc_main;
