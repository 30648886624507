import Vue from 'vue'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import router from './router'
import 'es6-promise/auto'
import './assets/css/common.less'
import '@/assets/iconfont/iconfont.css'
import moment from 'moment';
import commons from '@/utils/commons.js'
import config from '@/config'
import util from '@/utils/util.js'
import btn from '@/components/Button.vue'
import 'moment/locale/zh-cn';
import ProcessStatus from "@/views/business/components/processstatus.vue"
import AuditDocshow from "@/views/business/components/auditDocshow.vue"
import AuditShow from "@/views/business/components/auditshow.vue"
import AuditDate from "@/views/business/components/auditDate.vue"
import AuditUser from "@/views/business/components/auditUser.vue"
import AuditPrint from "@/views/business/components/auditPrint.vue"
import Audit from "@/views/business/components/audit.vue"
import VueCompositionAPI from "@vue/composition-api";
import { TUIComponents, TUIChatKit } from "./TUIKit";
import { TUITranslateService,TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import TUINotification from './TUIKit/components/TUINotification/index';
import { locales } from "./locales";

// TUIChatKit add TUIComponents
TUIChatKit.components(TUIComponents, Vue);
// TUIChatKit init
TUIChatKit.init();
TUITranslateService.provideLanguages(locales);
TUITranslateService.useI18n();

moment.locale('zh-cn')
Vue.prototype.$message = message
Vue.prototype.$moment = moment
Vue.prototype.$commons = commons
Vue.prototype.$config = config
Vue.prototype.$util = util

Vue.config.productionTip = false

Vue.component('p-button', btn)
Vue.component('processStatus', ProcessStatus)
Vue.component('audit', Audit)
Vue.component('auditdocshow', AuditDocshow)
Vue.component('auditshow', AuditShow)
Vue.component('auditDate', AuditDate)
Vue.component('auditUser',AuditUser)
Vue.component('auditPrint', AuditPrint)
Vue.use(VueCompositionAPI);
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Antd)
Vue.use(VueAxios, axios)

new Vue({
  store,
  router,
  axios: VueAxios,
  render: h => h(App),
}).$mount('#app')

/**
 * Init TUINotification configuration.
 * 初始化 TUINotification 相关配置信息
 */
TUINotification.setNotificationConfiguration({
  // 是否显示预览信息
  showPreviews: true,
  // 是否允许通知
  allowNotifications: true,
  // 通知标题
  notificationTitle: "Tencent Cloud Chat",
  // 通知图标
  notificationIcon: "https://web.sdk.qcloud.com/im/demo/latest/faviconnew.png",
});



/**
 * Listen for new messages and use notification components.
 * This capability is only available in the web environmen.
 * 使用 TUI 相关能力监听 newMessageList 字段 进行消息通知
 * 该能力仅可使用在原生 Web 环境下
 */
TUIStore.watch(StoreName.CHAT, {
  // 监听 newMessageList 字段的变化
  newMessageList: (newMessageList) => {
    // 回调函数返回新的 messageList
    if (Array.isArray(newMessageList)) {
      newMessageList.forEach(message => TUINotification.notify(message));
    }
  }
});
